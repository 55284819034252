<template>
  <div class="panel-body">
    <div class="parent ex-1">
      <template v-for="(row, key) in datas" :key="row.id">
        <div class="d-md-flex d-block text-md-start text-center item">
          <div class="media-body">
            <div
              class="d-flex d-block justify-content-between align-items-center flex-column flex-md-row"
            >
              <div class="item__heading">
                <FontAwesomeIcon
                  v-if="icon"
                  :icon="'fa-light fa-' + icon"
                  class="item__icon"
                />
                <a
                  v-if="href"
                  :href="row[href]"
                  class="item__img"
                  target="_blank"
                  ><img v-if="picture && mime" :src="setPicture(row[picture])"
                /></a>
                <div class="item__titleLine textStyle">
                  <div
                    v-if="titleKey && titleKey === 'key'"
                    class="item__title"
                  >
                    {{ key }}
                  </div>
                  <div v-else-if="titleKey" class="item__title">
                    {{ row[titleKey] }}
                  </div>
                  <div
                    v-else
                    :class="
                      'item__title ' +
                      (titleParams && titleParams === 'dashboard'
                        ? '-dashboard'
                        : '')
                    "
                  >
                    {{ row.title }}
                  </div>
                  <span v-if="row.date && !titleOnly" class="item__date"
                    >{{ $t("on") }} {{ $d(row.date, { key: "long" }) }}</span
                  >
                  <div
                    v-if="row.identity && titleKey !== 'identity'"
                    class="item__identity"
                  >
                    {{ $t("by") }} {{ row.identity }}
                  </div>
                  <div
                    v-if="row.monthName && row.year && !titleOnly"
                    class="item__date"
                  >
                    {{ row.monthName }} {{ row.year }}
                  </div>
                  <span v-if="row.duration && !titleOnly" class="item__date">{{
                    row.duration
                  }}</span>
                  <div
                    v-if="row.description && !titleOnly"
                    v-html="row.description"
                  ></div>
                  <a
                    v-if="href && !titleOnly"
                    :href="row[href]"
                    target="_blank"
                    >{{ row[href] }}</a
                  >
                </div>
              </div>
              <div class="d-flex">
                <ListActionsBlockComponent
                  :id="row.id"
                  :actions="actions"
                  :row="row"
                  @click="clickAction"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ListActionsBlockComponent from "@/components/blocks/ListActionsBlockComponent.vue";
import { pictureManager } from "@/services/utilities/picture-manager";

export default {
  name: "SimpleListComponent",
  props: {
    datas: {
      type: [],
      required: true,
      default: [],
    },
    actions: {
      type: [],
      required: true,
      default: [],
    },
    icon: {
      type: String,
      required: false,
    },
    picture: {
      type: String,
      required: false,
    },
    mime: {
      type: String,
      required: false,
    },
    href: {
      type: String,
      required: false,
    },
    titleKey: {
      type: String,
      required: false,
    },
    titleOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    titleParams: {
      type: String,
      required: false,
    },
  },
  methods: {
    clickAction(payload) {
      this.$emit(payload.action.action, payload.row);
    },

    setPicture(picture) {
      return pictureManager.setPicture(picture);
    },
  },
  components: {
    ListActionsBlockComponent,
    FontAwesomeIcon,
  },
};
</script>

<style lang="scss" scoped>
.item {
  padding: 10px;
  border: 1px solid $grey;
  border-radius: 6px;
  min-height: 50px;
  align-items: center;
  margin-bottom: 10px;

  @include up-to-md {
    padding: 15px;
  }

  &__titleLine {
    font-size: 14px;
    line-height: 14px;

    @include up-to-md {
      line-height: 16px;
    }
  }

  &__title {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-color);

    &.-dashboard {
      font-size: 0.875rem;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    height: 100%;

    @include up-to-md {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-right: 0;
    }
  }

  &__icon {
    margin-right: 15px;
    font-size: 22px;

    @include up-to-md {
      margin-right: 0;
      font-size: 25px;
      margin-bottom: 10px;
    }
  }

  &__date,
  &__identity {
    font-size: 12px;
    color: $m-color_6;
  }

  &__midContent {
    padding-top: 8px;
  }

  &__editIcon {
    width: 12px;
    height: 12px;
  }

  &__img {
    display: block;
    width: 150px;
    height: 150px;
    margin-right: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
