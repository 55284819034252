<template>
  <div v-for="category in skills" :key="category" class="subCategory">
    <span class="category-title px-4 py-2">
      {{ category.name }}
    </span>
    <div class="py-2 px-4">
      <SkillFrontListComponent
        v-if="category.skills && Object.keys(category.skills).length"
        :index="index"
        :name="category.name"
        :skills="category.skills"
        :replyHistoric="replyHistoric"
        :historicLoading="historicLoading"
        :skillId="skillId"
        @update="update"
        @openJustificativeForm="openForm"
        @showHistoric="showHistoric"
      />
      <SkillSubCategoryComponent
        v-if="category.daughters && Object.keys(category.daughters).length"
        :index="index + 1"
        :skills="category.daughters"
        :replyHistoric="replyHistoric"
        :skillId="skillId"
        @update="update"
        @openJustificativeForm="openForm"
        @showHistoric="showHistoric"
        },
      />
    </div>
  </div>
</template>

<script>
import SkillFrontListComponent from "@/components/skills/SkillFrontListComponent.vue";

export default {
  name: "SkillSubCategoryComponent",
  components: { SkillFrontListComponent },
  props: {
    skills: {
      type: [],
      required: true,
      default: [],
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    replyHistoric: {
      type: Object,
      required: false,
      default: null,
    },
    skillId: {
      type: Number,
      required: false,
      default: null,
    },
    historicLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    showHistoric(skill) {
      this.$emit("showHistoric", skill);
    },

    update(skill) {
      this.$emit("update", skill);
    },

    openForm(id) {
      this.$emit("openJustificativeForm", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.subCategory {
  margin-top: 20px;

  background-color: #f1f2f3;
  border: 5px solid $white;
  border-radius: 6px;

  &__title {
    border-top: 2px solid var(--primary-color);
    padding: 6px 0;
  }
}

.category-title {
  background-color: $primary;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white;
  font-size: 19px;
  font-weight: 500;
}
</style>
