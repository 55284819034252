import { apiConnection } from "@/services/api-connection";
import { useNotificationStore } from "@/store/notifications/notification";

class MessengerMessageManager {
  interval = null;
  intervalLength = 5000;
  messageId = null;
  loading = false;

  STATUS_WAITING = "waiting";
  STATUS_PROCESSING = "processing";
  STATUS_FAILED = "failed";
  STATUS_ERROR = "error";
  STATUS_SUCCESS = "success";

  startPing = (messageId, open = true) => {
    const store = useNotificationStore();
    store.setStatus(this.STATUS_WAITING);
    store.setOpen(open);
    this.messageId = messageId;
    this.setInterval();
  };

  setInterval = () => {
    this.interval = setInterval(this.ping, this.intervalLength);
  };

  ping = () => {
    if (!this.loading) {
      const store = useNotificationStore();
      this.loading = true;
      apiConnection
        .get("/app/messenger-monitoring/" + this.messageId)
        .then((res) => {
          store.setLast(res);

          const exist = store.messages.find((message) => message.id === res.id);
          if (!exist) {
            store.addMessage(res);
          } else {
            exist.status = res.status;
            exist.totalIterations = res.totalIterations;
            exist.currentIterations = res.currentIterations;
          }
          store.setStatus(res.status);
          if (
            res.status === this.STATUS_SUCCESS ||
            res.status === this.STATUS_ERROR
          ) {
            this.stopInterval();
          }
          this.loading = false;
        });
    }
  };

  stopInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  };

  get() {
    return apiConnection.get("/app/messenger-monitoring/list").then((res) => {
      const store = useNotificationStore();
      store.setList(res);
      this.retrieveProcessingMessage();
    });
  }

  retrieveProcessingMessage() {
    const store = useNotificationStore();
    if (store.list && Object.keys(store.list).length) {
      Object.values(store.list).forEach((message) => {
        if (
          message.status === this.STATUS_WAITING ||
          message.status === this.STATUS_PROCESSING
        ) {
          store.addMessage(message);
          this.startPing(message.id, false);
          store.setStatus(this.STATUS_PROCESSING);
        }
      });
    }
  }
}

export const messengerMessageManager = new MessengerMessageManager();
