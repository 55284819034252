import { defineStore } from "pinia";

export const useNotificationStore = defineStore({
  id: "Notification",
  state: () => {
    return {
      status: "",
      last: null,
      open: false,
      messages: [],
      list: [],
      listOpened: false,
    };
  },

  actions: {
    setStatus(status) {
      this.status = status;
    },
    setLast(notification) {
      this.notification = notification;
    },
    setOpen(open) {
      this.open = open;
    },
    addMessage(message) {
      this.messages.push(message);
    },
    setList(list) {
      this.list = list;
    },
    setListOpened(value) {
      console.log(value);
      this.listOpened = value;
    },
  },
});
