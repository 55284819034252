<template>
  <div
    :id="'questionnaire-block-' + year.year"
    :class="'widget' + (accordion ? ' accordion' : '')"
  >
    <div
      :id="'questionnaire-toggle-' + year.year"
      :aria-expanded="false"
      :class="'widget-heading' + (!openCollapse ? ' collapsed' : '')"
      :aria-controls="'collapse-questionnaire-' + year.year"
      :data-bs-toggle="accordion ? 'collapse' : ''"
      :data-bs-target="accordion ? '#collapse-questionnaire-' + year.year : ''"
      role="tab"
      @click="expand($event, year.year)"
    >
      <h2
        class="textStyle"
        v-if="questionnaireConfig && questionnaireConfig.termQuestionnaire"
      >
        {{ fistLetterToUppercase(questionnaireConfig.termQuestionnaire) }}
        <span v-if="Object.keys(questionnaires).length !== 1">{{
          year.year
        }}</span>
      </h2>
      <FontAwesomeIcon
        v-if="accordion"
        class="chevron"
        icon="fa-light fa-chevron-up"
      />
    </div>
    <div
      :id="'collapse-questionnaire-' + year.year"
      :class="!openCollapse ? 'collapse' : 'collapse show'"
      :aria-labelledby="'collapse-questionnaire-' + year.year"
    >
      <div class="widget-content">
        <div
          v-for="category in year.categories"
          :key="category"
          class="category"
        >
          <div
            class="category__heading"
            v-if="
              category.originalQuestionnaires &&
              category.originalQuestionnaires.length
            "
          >
            <div
              :class="
                'category__title -heading blockStyle2 secondary-color' +
                (hideIcons ? ' w-100' : '')
              "
            >
              {{ category.title }}
            </div>
            <div v-if="!hideIcons" class="category__roles -heading blockStyle">
              <div
                v-if="
                  apprenticeProfileLabel &&
                  apprenticeProfileLabel.title &&
                  apprenticeProfileLabel.titleAbbreviation
                "
                class="category__role"
                v-tooltip="{ title: apprenticeProfileLabel.title }"
              >
                {{ apprenticeProfileLabel.titleAbbreviation }}
              </div>
              <div
                v-if="
                  tutorProfileLabel &&
                  tutorProfileLabel.title &&
                  tutorProfileLabel.titleAbbreviation
                "
                :title="tutorProfileLabel.title"
                class="category__role"
                v-tooltip="{ title: tutorProfileLabel.title }"
              >
                {{ tutorProfileLabel.titleAbbreviation }}
              </div>
              <div
                v-if="
                  apprenticeMasterProfileLabel &&
                  apprenticeMasterProfileLabel.title &&
                  apprenticeMasterProfileLabel.titleAbbreviation
                "
                class="category__role"
                v-tooltip="{ title: apprenticeMasterProfileLabel.title }"
              >
                {{ apprenticeMasterProfileLabel.titleAbbreviation }}
              </div>
              <div
                v-if="
                  triad.studeaManagerColumn &&
                  studeaManagerProfileLabel &&
                  studeaManagerProfileLabel.titleAbbreviation
                "
                class="category__role"
              >
                {{ studeaManagerProfileLabel.titleAbbreviation }}
              </div>
            </div>
          </div>
          <QuestionnaireListComponent
            :questionnaires="category.originalQuestionnaires"
            @copyQuestionnaire="copyQuestionnaire"
            @removeQuestionnaire="removeQuestionnaire"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { triadManager } from "@/services/triad/triad-manager";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useUserStore } from "@/store/user/user";
import { usePlatformStore } from "@/store/platform/platform";
import QuestionnaireListComponent from "@/components/questionnaire/QuestionnairesListComponent.vue";

export default {
  name: "QuestionnaireListBlockComponent",
  props: {
    hideIcons: {
      type: Boolean,
      required: false,
      default: false,
    },
    accordion: {
      type: Boolean,
      required: false,
      default: false,
    },
    year: {
      type: [],
      required: true,
      default: [],
    },
  },
  data() {
    return {
      loading: false,
      modalModels: [],
      todayYear: new Date().getFullYear(),
      openCollapse: false,
      key: 0,
      apprenticeProfile: triadManager.APPRENTICE_PROFILE,
      tutorProfile: triadManager.TUTOR_PROFILE,
      apprenticeMasterProfile: triadManager.APPRENTICE_MASTER_PROFILE,
      studeaManagerProfile: triadManager.STUDEA_MANAGER_PROFILE,
    };
  },
  computed: {
    ...mapState(useQuestionnaireStore, {
      toSign: (store) => store.toSign,
      toComplete: (store) => store.toComplete,
      questionnaires: (store) => store.questionnaires,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
      userProfile: (store) => store.userProfile,
    }),
    ...mapState(usePlatformStore, {
      questionnaireConfig: (store) => store.questionnaireConfig,
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
      tutorProfileLabel: (store) => store.tutorProfileLabel,
      studeaManagerProfileLabel: (store) => store.studeaManagerProfileLabel,
      apprenticeMasterProfileLabel: (store) =>
        store.apprenticeMasterProfileLabel,
    }),
  },
  mounted() {
    window.addEventListener("resize", this.setIconSize);
    if (this.accordion) {
      this.isCollapseOpened();
    }
  },
  methods: {
    fistLetterToUppercase(str) {
      return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
    },

    formatDate(date) {
      return dateFormatter.format(date, "short");
    },

    dates(questionnaire) {
      const infos = questionnaire[this.user.type + "_infos"];
      const start_date = dateFormatter.format(infos.start_date);
      const end_date = dateFormatter.format(infos.end_date);

      return start_date + "-" + end_date;
    },

    setIconSize() {
      const size = window.innerWidth < 768 ? "12px" : "15px";
      const icons = document.getElementsByClassName("category__icon");
      for (let i = 0; i < icons.length; i++) {
        icons[i].setAttribute("width", size);
      }
    },

    expand(e, year) {
      const accordion = e.currentTarget;
      if (this.accordion && !accordion.classList.contains("collapsed")) {
        this.scrollTo(year);
      }
    },

    scrollTo(year) {
      const block = document.getElementById("questionnaire-block-" + year);
      const offset = 150;
      setTimeout(function () {
        window.scrollTo({
          behavior: "smooth",
          top:
            block.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            offset,
        });
      }, 200);
    },

    isCollapseOpened() {
      if (!this.toComplete.length || !this.toSign.length) {
        if (this.todayYear === this.year.year) {
          this.openCollapse = true;
        }
      } else {
        this.openCollapse = false;
      }
    },

    copyQuestionnaire(id) {
      this.$emit("copyQuestionnaire", id);
    },

    removeQuestionnaire(id) {
      this.$emit("removeQuestionnaire", id);
    },
  },
  components: {
    QuestionnaireListComponent,
    FontAwesomeIcon,
  },
};
</script>

<style lang="scss" scoped>
.widget {
  height: auto;

  &-content {
    @include up-to-md {
      padding: 0 10px 10px 10px;
    }
  }

  &-heading {
    @include up-to-md {
      padding: 20px 10px;
    }
  }
}

.category {
  @include up-to-md {
    font-size: 12px;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    background: $purple;
    color: $white;
    border-radius: $radius;
    text-transform: uppercase;
    font-weight: 700;
    overflow: hidden;
  }

  &__line {
    display: flex;
    color: $blue;

    @include dark-theme {
      color: $white;
    }
  }

  &__roles {
    display: flex;
    width: 25%;
    background: $white;
    justify-content: space-between;

    @include dark-theme {
      background: $m-color_10;
    }

    @include up-to-xl {
      width: 30%;
    }

    @include up-to-lg {
      width: 40%;
    }

    &.-heading {
      background: $blue;

      @include dark-theme {
        background: $blue;
      }
    }
  }

  &__role {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: none;
    border: none;

    svg {
      font-size: 16px;
    }

    .fa-check {
      color: $green;
    }
  }

  &__title {
    width: 80%;
    padding: 10px;
    font-weight: 300;
    background: none;
    border: none;
    display: inline;
    color: $m-color_9;
    text-align: left;

    &.-canBeCopiedBy {
      text-decoration: underline;
    }
  }
}

a.dropdown-item {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .title {
    padding-right: 20px;
  }
}

.secondary-color {
  background-color: var(--secondary-color) !important;
}

.info {
  padding: 10px 0 10px 5px;
}

.icon-secondary-color {
  color: var(--secondary-color) !important;
}

.copyTitle {
  background: var(--secondary-color);
  border-radius: 6px;
  text-align: center;
  padding: 0.2rem;
  color: $white;
}
</style>
