<template>
  <div class="widget">
    <div class="widget-heading justify-content-start">
      <button
        class="btn-icon me-2"
        v-tooltip="{ title: $t('back') }"
        @click="$router.back()"
      >
        <FontAwesomeIcon icon="fa-light fa-arrow-left" />
      </button>
      <h2>
        {{
          $tc("global.view.title", 1, {
            name: $t("admin.mail.title"),
          })
        }}
      </h2>
    </div>
    <div class="widget-content">
      <p class="m-0 fw-bold">Destinataires :</p>
      <span v-for="(report, index) in mail.sendReports" :key="report.id"
        >{{ index ? ", " : "" }} {{ report.email }}</span
      >
      <h6 class="my-3 fw-bold">{{ mail.title }}</h6>
      <p v-html="mail.body"></p>
    </div>
  </div>
</template>

<script>
import { adminManager } from "@/services/admin/admin-manager";

export default {
  name: "MailViewView",
  components: {},
  data() {
    return {
      mail: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.getDatas();
    },

    getDatas() {
      adminManager.get("mail", this.$route.params.id).then((data) => {
        this.mail = data;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
