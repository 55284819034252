<template>
  <div class="row">
    <div class="col-lg-10 order-1 order-lg-0">
      <div class="widget">
        <div class="widget-heading -list">
          <h1 class="textStyle">
            {{
              $tc("global.list.title", 1, {
                name: this.$tc("admin.user.title", 2).toLowerCase(),
              })
            }}
            {{
              isFiltered
                ? "(" +
                  (maxResult ? maxResult : Object.keys(datas).length) +
                  " résultat" +
                  ((maxResult ? maxResult : Object.keys(datas).length) > 1
                    ? "s"
                    : "") +
                  " filtré" +
                  ((maxResult ? maxResult : Object.keys(datas).length) > 1
                    ? "s"
                    : "") +
                  ")"
                : ""
            }}
          </h1>
          <div>
            <button
              v-if="isFiltered"
              class="btn-icon me-1"
              v-tooltip="{ title: $t('init_search') }"
              type="button"
              @click="resetSearch"
            >
              <FontAwesomeIcon icon="fa-light fa-arrows-rotate" />
            </button>
            <button
              class="btn-icon"
              v-tooltip="{ title: $t('search_motor') }"
              type="button"
              @click="openSearch"
            >
              <FontAwesomeIcon icon="fa-light fa-magnifying-glass" />
            </button>
          </div>
        </div>
        <div class="widget-content">
          <SortTableComponent
            :actions="actions"
            :apiFilters="true"
            :columns="columns"
            :datas="datas"
            :loading="loading"
            :idKey="4"
            :modalTitle="modalTitle"
            :models="models"
            :isSearchAvailable="true"
            :maxResult="maxResult"
            :keyword="keyword"
            type="institution"
            ref="sortTable"
            @generatePassword="generatePassword"
            @lastEmailLink="lastEmailLink"
            @switchUser="switchUser"
            @remove="remove"
            @initModel="initModel"
            @openSearch="openSearch"
            @refresh="refresh"
          />
        </div>
      </div>
    </div>
    <div class="col-lg-2 accordion order-lg-1 order-0" id="accordionAction">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingAction">
          <button
            :disabled="windowWidth > 991"
            :class="
              'accordion-button ' + (windowWidth < 991 ? 'collapsed' : '')
            "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseAction"
            aria-expanded="true"
            aria-controls="collapseAction"
          >
            {{ $t("actions") }}
          </button>
        </h2>
        <div
          id="collapseAction"
          :class="
            'accordion-collapse collapse ' + (windowWidth > 991 ? 'show' : '')
          "
          aria-labelledby="headingAction"
          data-bs-parent="#accordionAction"
        >
          <div class="accordion-body">
            <router-link
              v-access:[permissions]="accessManager().EDIT"
              :to="{ name: 'adminUsersAdd' }"
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-plus" />
              {{
                $tc("global.add.title", 1, {
                  name: this.$tc("admin.user.title", 1).toLowerCase(),
                })
              }}
            </router-link>
            <router-link
              :to="{
                name: 'adminStudeaManagerProfileList',
                params: { alias: 'studea-manager-profile' },
              }"
              v-access:[permissions]="{
                access: accessManager().VIEW,
                code: adminManager().STUDEA_ADMIN_ROLE,
              }"
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-gears" />
              {{ $t("admin.user.profile_config") }}
            </router-link>
            <router-link
              :to="{
                name: 'UserMerge',
              }"
              v-access:[permissions]="accessManager().EDIT"
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-merge" />
              {{ $t("admin.user.merge") }}
            </router-link>
            <router-link
              v-access:[permissions]="accessManager().EDIT"
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
              :to="{ name: 'UsersProfileTitleList' }"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-pen" />
              {{ $t("admin.user.profile_title") }}
            </router-link>
            <router-link
              v-access:[permissions]="accessManager().EDIT"
              :to="{ name: 'UsersImport', params: { alias: 'user' } }"
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-download" />
              {{ $t("admin.user.import_users") }}
            </router-link>
            <button
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
              v-access:[permissions]="accessManager().EDIT"
              @click="exportForm"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-upload" />
              {{
                $tc("global.export.title", 2, {
                  name: this.$tc("admin.user.title", 2).toLowerCase(),
                })
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalFormComponent
    :models="filterModels"
    :title="
      $tc('global.search.title', 1, {
        name: this.$tc('admin.user.title', 2).toLowerCase(),
      })
    "
    :storageKey="sessionStorageKey"
    @search="search"
    @submit="submit"
  />
  <ModalBlockComponent :modalTitle="modalTitle" :models="models" />
  <ModalComponent
    :title="mailTitle"
    :content="mailBody"
    :opened="mailOpened"
    @hidden="mailHidden"
  />
</template>

<script>
import { mapState } from "pinia";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { notificationManager } from "@/services/utilities/notification-manager";
import { adminManager } from "@/services/admin/admin-manager";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import { formManager } from "@/services/form/form-manager";
import ModalBlockComponent from "@/components/blocks/ModalBlockComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { accessManager } from "@/services/security/access-manager";
import { useThemeStore } from "@/store/theme/theme";
import { useUserStore } from "@/store/user/user";
import { fileManager } from "@/services/file/file-manager";

export default {
  name: "UsersListView",
  components: {
    ModalComponent,
    ModalBlockComponent,
    ModalFormComponent,
    SortTableComponent,
    FontAwesomeIcon,
  },
  data() {
    return {
      loading: false,
      listParams: [],
      datas: [],
      isSearchOpen: false,
      isFiltered: false,
      sessionStorageKey: "studea-admin-users",
      models: [],
      modalTitle: "",
      formModalTitle: "",
      formModalType: "",
      maxResult: 0,
      mailTitle: "",
      mailBody: "",
      keyword: "",
      initializing: false,
      mailOpened: false,
      actions: [
        {
          translation: "admin.lastEmail",
          icon: "envelope",
          type: "primary",
          action: "lastEmailLink",
          cfaStyle: true,
          access: accessManager.NONE,
        },
        {
          translation: "admin.generatePassword",
          icon: "key",
          type: "primary",
          action: "generatePassword",
          cfaStyle: true,
          access: accessManager.NONE,
        },
        {
          translation: "admin.switchUser",
          translationParams: { name: "name", id: "id" },
          icon: "user",
          type: "primary",
          action: "switchUser",
          cfaStyle: false,
          access: accessManager.NONE,
        },
        {
          translation: "admin.modify",
          translationParams: { name: "name", id: "id" },
          icon: "pen",
          type: "success",
          actionType: "router",
          action: "adminUsersEdit",
          cfaStyle: false,
          access: accessManager.EDIT,
        },
        {
          translation: "admin.delete",
          translationParams: { name: "name", id: "id" },
          icon: "trash",
          type: "danger",
          action: "remove",
          cfaStyle: false,
          access: accessManager.DELETE,
        },
      ],
      columns: [],
      filterModels: [],
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState(useThemeStore, {
      locale: (store) => store.locale,
    }),
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  watch: {
    windowWidth() {
      this.updateWindowWidth();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  mounted() {
    if (!this.initializing) {
      this.init();
      window.addEventListener("resize", this.updateWindowWidth);
    }
  },
  beforeRouteUpdate() {
    if (!this.initializing) {
      this.init();
      window.addEventListener("resize", this.updateWindowWidth);
    }
  },
  methods: {
    adminManager() {
      return adminManager;
    },
    accessManager() {
      return accessManager;
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    generatePassword(row) {
      adminManager.resetUserPassword(row.id).then((res) => {
        let type = "error";
        let message = this.$t("admin.error");
        if (res.success) {
          type = "success";
          message = this.$t("admin.user.actions.reset_password.success");
        }
        notificationManager.showNotification(type, message);
      });
    },

    setKeyword() {
      if (this.$route.query.search) {
        this.keyword = this.$route.query.search;
      } else if (
        window.sessionStorage.getItem(this.sessionStorageKey + "-keywords")
      ) {
        this.keyword = window.sessionStorage.getItem(
          this.sessionStorageKey + "-keywords",
        );
      }
      this.listParams["keywords"] = this.keyword;
    },

    formatDate(date) {
      return dateFormatter.format(date, "long");
    },

    lastEmailLink(row) {
      adminManager.getLastPasswordLinkEmail(row.id).then((data) => {
        this.mailTitle =
          data.subject && data.date
            ? data.subject + " - " + this.formatDate(data.date)
            : this.$t("admin.user.no_mail_title");
        this.mailBody = data.body ? data.body : this.$t("admin.user.no_mail");
        this.mailOpened = true;
      });
    },

    mailHidden() {
      this.mailOpened = false;
      this.mailTitle = "";
      this.mailBody = "";
    },

    search(params) {
      if (this.formModalType === "search") {
        this.loading = true;
        this.listParams["offset"] = 0;
        this.$refs.sortTable.activePage = 1;
        this.listParams["search"] = params;
        this.getDatas();
        this.isFiltered = true;
      }
    },

    submit() {
      if (this.modalType === "export") {
        const result = formManager.processForm(this.filterModels);
        adminManager.postExport("user", result).then((file) => {
          if (file && file.data) {
            fileManager.saveFile(file.filename, file.data, file.filetype);
          }
        });
      }
    },

    init() {
      this.initializing = true;
      this.loading = true;
      this.setParams();
      this.getDatas();
    },

    setParams() {
      if (!this.listParams || !Object.keys(this.listParams).length) {
        this.listParams = {
          search: "",
          sortByField: 0,
          sortOrder: "ASC",
          limit: 10,
          offset: 0,
        };
      }
    },

    getDatas() {
      if (window.sessionStorage.getItem(this.sessionStorageKey)) {
        this.listParams["search"] = JSON.parse(
          window.sessionStorage.getItem(this.sessionStorageKey),
        );
        this.isFiltered = true;
      }
      if (this.isFiltered) {
        this.listParams["search"] = formManager.getQuery(
          this.listParams["search"],
        );
      }

      this.setKeyword();
      adminManager.list("user", false, this.listParams).then((data) => {
        if (data && data.columns) {
          this.columns = sortableTableManager.getEntriesColumns(
            data.columns,
            this.columns,
          );
          this.columns[3].sortable = false;
          this.datas = data.data;
          this.maxResult = data.count;
          this.initializing = false;
        }
        this.loading = false;
      });
    },

    getFilters() {
      this.filterModels = [];
      this.modalType = "search";
      this.sessionStorageKey = "studea-admin-users";
      this.modalTitle = this.$tc("global.search.title", 2, {
        name: this.$tc("admin.user.title", 2).toLowerCase(),
      });
      adminManager.form("user", null, {}, false, true).then((data) => {
        this.filterModels = data;
      });
    },

    openSearch() {
      this.formModalType = "search";
      this.getFilters();
      this.isSearchOpen = true;
    },

    resetSearch() {
      this.listParams = [];
      this.datas = [];
      this.$refs.sortTable.activePage = 1;
      this.columns = [];
      this.isFiltered = false;
      window.sessionStorage.removeItem(this.sessionStorageKey);
      window.sessionStorage.removeItem(this.sessionStorageKey + "-keywords");
      this.keyword = "";
      this.init();
    },

    remove(row) {
      adminManager.remove("studea-user", row.id, 0).then(() => {
        this.init();
      });
    },

    refresh(params) {
      this.listParams = params;
      window.sessionStorage.setItem(
        this.sessionStorageKey + "-keywords",
        this.listParams.keywords,
      );
      this.keyword = this.listParams.keywords;
      this.init();
    },

    switchUser(row) {
      adminManager.switchUser(row.id);
    },

    initModel(row, index, name) {
      this.modalTitle = "";
      this.models = [];
      row[index ? index : name].forEach((item) => {
        let type = "router";
        let label;
        let id;
        if (item.trainingCourses) {
          Object.values(item.trainingCourses).forEach((trainingCourse) => {
            label =
              item.institution.name +
              " - " +
              item.name +
              " : " +
              trainingCourse.startYear +
              " - " +
              trainingCourse.endYear;
            id = trainingCourse.id;
            let object = { label, type, id };
            this.models.push(object);
          });
        } else {
          label = item.displayName;
          id = item.id;
          let object = { label, type, id };
          this.models.push(object);
        }
      });
      this.modalTitle = this.$t("Training courses list");
    },

    exportForm() {
      this.modalType = "export";
      this.filterModels = [];
      this.sessionStorageKey = null;
      this.modalTitle = this.$tc("global.export.title", 2, {
        name: this.$tc("admin.user.title", 2).toLowerCase(),
      });
      adminManager.exportForm("user").then((models) => {
        this.filterModels = models;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-heading {
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &.-list {
    cursor: initial;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  &.collapsed {
    padding-bottom: 0;
  }
}

.widget {
  padding-bottom: 20px;
}

.widget-content {
  padding-bottom: 0;
}

.accordion-button {
  padding: 20px !important;
}
</style>
