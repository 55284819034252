<template>
  <ModalFormComponent
    :models="models"
    :title="$t(modalTitle)"
    @submit="sumbitJustificative(skillAnswer)"
  />
  <ScrollProgressBarComponent
    :data-length="progressBarLength"
    :step="getCurrentIndex() + 1"
    :title="$t('Category')"
  />
  <div class="container-fluid position-relative">
    <BlockLoader :loading="pdfLoading" />
    <div class="row">
      <div class="col-xl-3 col-lg-4 order-2 order-lg-0">
        <TriadMemberBlock />
      </div>
      <div class="col-xl-7 col-lg-8 order-1 order-lg-1">
        <div
          class="widget"
          v-if="
            lastSkillUpdate ||
            (currentSession &&
              currentSession.sessionSkillCategories &&
              !currentSession.sessionSkillCategories.length) ||
            (currentSession &&
              currentSession.sessionSkillCategories &&
              currentSession.sessionSkillCategories[0].skills &&
              !currentSession.sessionSkillCategories[0].skills.length)
          "
        >
          <div class="widget-heading flex-column border-0">
            <div v-if="lastSkillUpdate" class="w-100 badge badge-success mb-3">
              {{ $t("last_skill_update") }} :
              {{ lastSkillUpdate }}
            </div>
            <div
              v-if="
                triad.trainingCourse && triad.trainingCourse.skillPresentation
              "
              class="description"
              v-html="triad.trainingCourse.skillPresentation"
            ></div>
            <div
              v-if="
                (currentSession.sessionSkillCategories &&
                  !currentSession.sessionSkillCategories.length) ||
                (currentSession.sessionSkillCategories &&
                  currentSession.sessionSkillCategories[0].skills &&
                  !currentSession.sessionSkillCategories[0].skills.length)
              "
            >
              Aucune compétence disponible
            </div>
          </div>
          <BlockLoader :loading="loading" />
        </div>
        <div
          class="widget p-4 text-center"
          v-else-if="currentSession && currentSession.id"
        >
          {{ $t("no_skill_complete") }}
        </div>
        <div class="widget">
          <div class="widget-content">
            <ItemTabsBlock
              :loading="loading"
              v-if="sessions"
              :currentItem="currentSession"
              :itemList="sessions"
              trad="studea_manager.skill.list.empty_only"
              @changeItem="changeSession"
            />
            <div
              id="skill-categories"
              class="position-relative"
              v-if="currentSession && currentSession.sessionSkillCategories"
            >
              <template
                v-for="(
                  category, index
                ) in currentSession.sessionSkillCategories"
                :key="category.id"
              >
                <div
                  v-if="!category.mother"
                  :id="'category-' + index"
                  class="element"
                >
                  <button
                    :id="'heading-' + category.id"
                    :aria-controls="'category-' + category.id"
                    :aria-expanded="index === getCurrentIndex()"
                    :class="
                      'category__heading px-4 py-2' +
                      (index === getCurrentIndex() ? '' : ' off collapsed')
                    "
                    :data-bs-target="'#category-' + category.id"
                    data-bs-toggle="collapse"
                    type="button"
                    @click="collapse(index)"
                  >
                    <span class="category-title textStyle d-inline">
                      {{ category.name }}
                    </span>
                    <FontAwesomeIcon
                      class="textStyle"
                      icon="fa-light fa-chevron-down"
                    />
                  </button>
                  <div
                    :id="'category-' + category.id"
                    :aria-labelledby="'heading-' + category.id"
                    :class="
                      'collapse' + (index === getCurrentIndex() ? ' show' : '')
                    "
                    data-bs-parent="#skill-categories"
                  >
                    <div class="widget-content">
                      <SkillFrontListComponent
                        v-if="category.skills && category.skills.length"
                        :index="0"
                        :skills="category.skills"
                        :replyHistoric="replyHistoric"
                        :skillId="skillId"
                        :historicLoading="historicLoading"
                        @update="update"
                        @openJustificativeForm="openJustificativeForm"
                        @showHistoric="showHistoric"
                        :key="componentKey"
                      />
                      <div v-if="Object.keys(category.daughters).length">
                        <SkillSubCategoryComponent
                          :index="0"
                          :skills="category.daughters"
                          :replyHistoric="replyHistoric"
                          :skillId="skillId"
                          :historicLoading="historicLoading"
                          @update="update"
                          @openJustificativeForm="openJustificativeForm"
                          @showHistoric="showHistoric"
                          :key="componentKey"
                        />
                      </div>
                    </div>
                    <div
                      v-if="
                        category.skills &&
                        !category.skills.length &&
                        !Object.keys(category.daughters).length
                      "
                      class="widget-content"
                    >
                      {{ $t("no_skills") }}
                    </div>
                    <div class="widget-footer">
                      <div>
                        <button
                          v-if="index"
                          class="btn btnStyle btn-success prev"
                          @click="prev"
                        >
                          <FontAwesomeIcon icon="fa-light fa-chevron-left" />
                          {{ $t("previous") }}
                        </button>
                      </div>
                      <div>
                        <button
                          v-if="
                            index <
                            Object.keys(
                              currentSession.sessionSkillCategories.filter(
                                (skill) => !skill.mother,
                              ),
                            ).length -
                              1
                          "
                          class="btn btnStyle btn-success next"
                          @click="next"
                        >
                          {{ $t("next") }}
                          <FontAwesomeIcon icon="fa-light fa-chevron-right" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div v-else class="widget p-4 text-center">
              {{ $t("no_skill_available") }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 order-0 order-lg-2">
        <div class="sticky">
          <ActionsBlock
            v-if="actions"
            :actions="actions"
            :mobileCollapsable="true"
            @pdf="downloadPdf"
            @save="save"
            @excel="exportToExcel"
          />
          <div
            v-if="
              triad && triad.trainingCourse && triad.trainingCourse.skillLevels
            "
            class="widget"
          >
            <div class="widget-heading">
              <h2 class="textStyle">Barème de notation</h2>
            </div>
            <div class="widget-content">
              <div
                v-for="(skillLevel, index) in triad.trainingCourse.skillLevels"
                :key="skillLevel.id"
              >
                {{ index + 1 }} - {{ skillLevel }}<br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import ScrollProgressBarComponent from "@/components/utilities/ScrollProgressBarComponent.vue";
import { skillManager } from "@/services/skill/skill-manager";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import SkillFrontListComponent from "@/components/skills/SkillFrontListComponent.vue";
import SkillSubCategoryComponent from "@/components/skills/SkillSubCategoryComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TriadMemberBlock from "@/components/blocks/TriadMemberBlock.vue";
import { dateFormatter } from "@/services/utilities/date-formatter";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { fileManager } from "@/services/file/file-manager";
import ItemTabsBlock from "@/components/blocks/ItemTabsBlock.vue";
import { useTriadStore } from "@/store/triad/triad";
import { useUserStore } from "@/store/user/user";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useMainStore } from "@/store/main/main";

export default {
  name: "SkillsView",
  components: {
    ItemTabsBlock,
    ModalFormComponent,
    TriadMemberBlock,
    FontAwesomeIcon,
    SkillSubCategoryComponent,
    SkillFrontListComponent,
    ScrollProgressBarComponent,
    BlockLoader,
    ActionsBlock,
  },
  data() {
    return {
      response: {},
      loading: false,
      pageTitle: "",
      arrayIndex: [],
      currentIndex: 0,
      lastSkillUpdate: null,
      progressBarLength: 0,
      actions: [
        {
          translation: "See statistics",
          icon: "line-chart",
          type: "primary",
          actionType: "router",
          action: "skillsStats",
          cfaStyle: true,
        },
        {
          translation: "PDF export",
          icon: "file-pdf",
          type: "primary",
          action: "pdf",
          cfaStyle: true,
        },
        {
          translation: "Excel export",
          icon: "file-excel",
          type: "primary",
          action: "excel",
          cfaStyle: true,
        },
        {
          translation: "Save",
          icon: "floppy-disk",
          type: "success",
          action: "save",
          cfaStyle: false,
          separation: true,
          loading: "saving",
        },
      ],
      replyHistoric: {},
      skillId: null,
      componentKey: 0,
      historicLoading: false,
      models: [],
      modalTitle: "",
      skillAnswer: null,
      pdfLoading: false,
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
      skills: (store) => store.skills,
      sessions: (store) => store.sessions,
      currentSession: (store) => store.currentSession,
      currentSessionSkillReply: (store) => store.currentSessionSkillReply,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
    ...mapState(useMainStore, {
      saving: (store) => store.saving,
    }),
  },
  watch: {
    "triad.lastSkillReply"() {
      if (!this.currentSession || !this.currentSession.id) {
        this.initSession();
      }
      this.setUserSkillReply();
      trainingCourseManager.setPageTitle(
        trainingCourseManager.MODULE_STUDEA_SKILL,
      );
    },
    "triad.trainingCourse.skillCategories"() {
      this.initSession();
      this.setProgressBarLength();
    },
    currentSession() {
      this.setUserSkillReply();
    },
  },
  created() {
    trainingCourseManager.setPageTitle(
      trainingCourseManager.MODULE_STUDEA_SKILL,
    );
  },
  mounted() {
    this.setUserSkillReply();
    if (this.sessions && this.sessions.length) {
      this.initSession();
      this.sessions.forEach((session) => {
        const item = { session: session.id, index: 0 };
        this.arrayIndex.push(item);
      });
    }
  },
  methods: {
    update(payload) {
      let key = payload.type + "Value";
      if (payload.skill[key].value || payload.skill[key].value === 0) {
        this.response[payload.skill.id] = payload.skill[key].value;
      }
    },

    setProgressBarLength() {
      if (this.currentSession && this.currentSession.sessionSkillCategories) {
        this.progressBarLength =
          this.currentSession.sessionSkillCategories.filter(
            (category) => !category.mother,
          ).length;
      }
    },

    setUserSkillReply() {
      if (
        this.triad &&
        this.triad.lastSkillReply &&
        this.triad.lastSkillReply.length &&
        this.currentSession &&
        this.currentSession.id
      ) {
        this.setCurrentSessionSkillReply(
          this.triad.lastSkillReply.find(
            (session) => session.skillSessionId === this.currentSession.id,
          ),
        );
        if (this.currentSessionSkillReply) {
          this.lastSkillUpdate = null;
          // eslint-disable-next-line no-unused-vars
          const { skillSessionId, ...sessionSkillReplies } =
            this.currentSessionSkillReply;
          if (sessionSkillReplies) {
            Object.values(sessionSkillReplies).forEach((element) => {
              if (element && element.date && !this.lastSkillUpdate) {
                this.lastSkillUpdate = this.formatDate(element.date, "long");
              } else {
                if (
                  element &&
                  element.date &&
                  Date.parse(this.lastSkillUpdate) > Date.parse(element.date)
                ) {
                  this.lastSkillUpdate = this.formatDate(element.date, "long");
                }
              }
            });
          }
        }
      }
    },

    save() {
      if (Object.keys(this.response).length) {
        this.loading = true;
        this.sendSkills(this.response).then(() => {
          this.fetchSkills().then(() => {
            this.loading = false;
          });
        });
      }
    },

    downloadPdf() {
      this.pdfLoading = true;
      skillManager.downloadSkillToPdf().then((data) => {
        fileManager.saveFile(data.filename, data.data);
        this.pdfLoading = false;
      });
    },

    exportToExcel() {
      skillManager.downloadSkillToExcel().then((data) => {
        fileManager.saveFile(data.filename, data.data);
      });
    },

    collapse(index) {
      this.currentIndex = index;
      const sessionIndex = this.arrayIndex.findIndex(
        (item) => item.session === this.currentSession.id,
      );
      this.arrayIndex[sessionIndex] = {
        session: this.currentSession.id,
        index: index,
      };
      this.scrollTo();
    },

    next() {
      if (
        this.getCurrentIndex() <
        Object.keys(this.currentSession.sessionSkillCategories).length
      ) {
        const sessionIndex = this.findSessionIndexIntoArrayIndex();
        this.arrayIndex[sessionIndex].index++;
      }

      this.scrollTo();
    },

    prev() {
      if (this.getCurrentIndex() > 0) {
        const sessionIndex = this.findSessionIndexIntoArrayIndex();
        this.arrayIndex[sessionIndex].index--;
      }
      this.scrollTo();
    },

    findSessionIndexIntoArrayIndex() {
      return this.arrayIndex.findIndex(
        (item) => item.session === this.currentSession.id,
      );
    },

    scrollTo() {
      const element = document.getElementById("category-" + this.currentIndex);
      if (element) {
        setTimeout(() => {
          window.scrollTo({
            behavior: "smooth",
            top:
              element.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              130,
          });
        }, 50);
      }
    },

    showHistoric(skill) {
      if (this.skillId !== skill) {
        this.historicLoading = true;
        this.replyHistoric = null;
        this.skillId = skill;
        skillManager.getTriadSkillsHistoric(skill).then((response) => {
          this.replyHistoric = response;
          this.componentKey += 1;
          this.historicLoading = false;
        });
      } else {
        this.skillId = null;
      }
    },

    openJustificativeForm(id) {
      this.models = [];
      this.modalTitle = "";
      skillManager.getJustificativeForm(id).then((response) => {
        this.models = response;
        this.modalTitle = "justificative_piece";
        this.skillAnswer = id;
      });
    },

    sumbitJustificative(id) {
      const result = formManager.processForm(this.models);
      skillManager.submitJustificativeForm(id, result).then(() => {
        notificationManager.showNotification(
          "success",
          this.$t("justificativePieceSend"),
        );
      });
    },

    initSession() {
      this.setCurrentSession(this.sessions[0]);
      this.setProgressBarLength();
    },

    changeSession(id) {
      this.response = {};
      this.setCurrentSession(
        this.sessions.find((session) => session.id === id),
      );
      this.setUserSkillReply();
    },

    formatDate(date) {
      return dateFormatter.format(date, "short");
    },

    getCurrentIndex() {
      const item = this.arrayIndex.find(
        (element) => element.session === this.currentSession.id,
      );
      if (item) {
        return item.index;
      }
      return 0;
    },

    ...mapActions(useTriadStore, [
      "setCurrentSession",
      "setCurrentSessionSkillReply",
      "sendSkills",
      "fetchSkills",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.description {
  white-space: pre-wrap;
}

button {
  border: none;
}

.next,
.prev {
  min-width: 110px;

  svg {
    width: 10px;
    height: auto;
    margin-left: 5px;
  }
}

.prev {
  svg {
    margin-left: 0;
    margin-right: 5px;
  }
}

.category-title {
  font-size: 19px;
  font-weight: 500;
  color: $white !important;
}

.category__heading {
  background-color: $primary;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    color: $white !important;
    transform: rotate(0);
    transition: all 300ms ease-in-out;
    width: 20px;
    height: 20px;
  }

  &.collapsed {
    svg {
      transform: rotate(180deg);
      margin-left: 10px;
    }
  }
}

.element {
  -webkit-box-shadow: 0px -8px 12px -9px rgba(42, 47, 51, 0.59);
  box-shadow: 0px -8px 12px -9px rgba(42, 47, 51, 0.59);
  margin-bottom: 20px;
}
</style>
