import { i18n } from "@/i18n";
import ErrorView from "@/views/Error/ErrorView.vue";
import MaintenanceView from "@/views/Error/MaintenanceView.vue";
import GlobalLayoutView from "@/views/Global/GlobalLayoutView.vue";

export default {
  path: "/error",
  name: "ErrorLayout",
  component: GlobalLayoutView,
  redirect: { name: "Error" },
  meta: {
    breadcrumb: true,
    title: i18n.global.t("page.error"),
  },
  children: [
    {
      path: "error",
      name: "Error",
      component: ErrorView,
      meta: {
        title: i18n.global.t("page.error"),
      },
    },
    {
      path: "maintenance",
      name: "Maintenance",
      component: MaintenanceView,
      meta: {
        title: i18n.global.t("page.maintenance"),
      },
    },
  ],
};
