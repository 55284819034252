<template>
  <li role="presentation">
    <hr
      v-if="separator"
      role="separator"
      aria-orientation="horizontal"
      size="0"
      class="dropdown-divider"
    />
  </li>
  <li role="presentation">
    <a href="javascript:;" class="dropdown-item">
      <div class="media file-upload">
        <div class="media-aside align-self-center">
          <FontAwesomeIcon icon="fa-light fa-file-pdf" />
        </div>
        <div class="media-body align-items-center">
          <div class="data-info">
            <h6>Votre questionnaire</h6>
            <a
              v-if="
                message.status === messengerMessageManager().STATUS_SUCCESS &&
                apiPath
              "
              :href="apiPath + message.action"
              >Télécharger</a
            >
          </div>
          <div
            class="icon-status"
            :class="{
              '-success':
                message.status === messengerMessageManager().STATUS_SUCCESS,
              '-waiting':
                message.status === messengerMessageManager().STATUS_WAITING ||
                message.status === messengerMessageManager().STATUS_PROCESSING,
              '-error':
                message.status === messengerMessageManager().STATUS_FAILED ||
                message.status === messengerMessageManager().STATUS_ERROR,
              '-progress': message.totalIterations && message.currentIterations,
            }"
          >
            <font-awesome-icon :icon="['fal', getIcon(message.status)]" />
            <NotificationProgressComponent
              v-if="message.totalIterations && message.currentIterations"
              :message="message"
            />
          </div>
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";
import { fileManager } from "@/services/file/file-manager";
import NotificationProgressComponent from "@/components/notifications/NotificationProgressComponent.vue";

export default {
  name: "NotificationComponent",
  components: { NotificationProgressComponent },
  props: {
    message: {
      type: Object,
      required: true,
    },
    separator: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      apiPath: fileManager.getApiPath()
        ? fileManager.getApiPath().slice(0, -4)
        : null,
    };
  },
  methods: {
    messengerMessageManager() {
      return messengerMessageManager;
    },

    getIcon(status) {
      switch (status) {
        case this.messengerMessageManager().STATUS_SUCCESS:
          return "check";
        case this.messengerMessageManager().STATUS_ERROR:
        case this.messengerMessageManager().STATUS_FAILED:
          return "xmark";
        case this.messengerMessageManager().STATUS_PROCESSING:
        case this.messengerMessageManager().STATUS_WAITING:
          return "hourglass-half";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h6 {
  color: var(--primary-color);

  &:hover {
    color: var(--primary-color) !important;
  }
}

.dropdown-item {
  &:hover {
    h6 {
      color: var(--primary-color) !important;
    }
  }
}

.icon-status {
  background: var(--icon-color);
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;

  &.-success {
    --icon-color: #6ac7a4;
  }

  &.-error {
    --icon-color: #da5164;
  }

  &.-waiting {
    --icon-color: #00275e;
  }

  svg {
    color: $white !important;
    height: 16px !important;
    width: auto !important;
  }

  &.-progress {
    background: $white;

    svg {
      color: $blue !important;
    }
  }
}

.progressBar {
  inset: 0;
  border-radius: 50%;
  border: 2px solid $blue;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
  height: 28px;
}
</style>
