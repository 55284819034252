<template>
  <div class="messageProgress">
    <svg height="33" width="33" xmlns="http://www.w3.org/2000/svg">
      <circle
        r="13.5"
        cx="15"
        cy="15"
        stroke="#00275e"
        stroke-width="2"
        fill="transparent"
        class="circle-dash"
        :style="{ 'stroke-dashoffset': dashOffset }"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "NotificationProgressComponent",
  components: {},
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dashOffset: 84,
      interval: null,
    };
  },
  watch: {
    "message.currentIterations"() {
      this.updateDashOffset();
    },
  },
  mounted() {
    this.updateDashOffset();
  },
  computed: {},
  methods: {
    updateDashOffset() {
      const purcent =
        this.message.currentIterations / this.message.totalIterations;
      const nextOffset = 84 - 84 * purcent;
      this.interval = setInterval(() => {
        this.dashOffset--;
        if (this.dashOffset <= nextOffset) {
          clearInterval(this.interval);
        }
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
.messageProgress {
  position: absolute;

  svg {
    width: 30px !important;
    height: 30px !important;
    transform: rotate(90deg);
  }
}

.circle-dash {
  stroke-dasharray: 84;
}
</style>
