<template>
  <div
    v-if="Object.keys(triad).length"
    class="widget blockStyle widget-transaction"
  >
    <div class="widget-heading">
      <h2 class="">Membres du trinome</h2>
    </div>
    <div class="widget-content">
      <div class="d-flex align-items-center justify-content-between mt-3">
        <router-link
          v-if="triad.apprentice"
          :to="{ name: 'profile', params: { id: triad.apprentice.cryptedId } }"
        >
          <div class="transactions-list">
            <div>
              <span class="me-2">
                <AvatarComponent
                  :user="triad.apprentice"
                  profile="apprentice"
                />
              </span>
              <div class="t-name">
                <h4 v-if="triad.apprentice && triad.apprentice.fullName">
                  {{ triad.apprentice.fullName }}
                </h4>
                <div
                  class="d-flex align-items-center mt-1"
                  v-if="apprenticeProfileLabel && apprenticeProfileLabel.title"
                >
                  <p>{{ apprenticeProfileLabel.title }}</p>
                  <span
                    v-if="findUserType(1).length"
                    v-tooltip="{ title: this.initTooltipTitle(1) }"
                  >
                    <FontAwesomeIcon
                      icon="fa-light fa-users"
                      class="secondary__user__btn"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
        <button
          v-if="triad.apprentice"
          v-access:[permissions]="{
            access: this.accessManager().ALL,
            code: this.studeaManagerManager().STUDEA_MANAGER_ALLOWED_TO_SWITCH,
          }"
          class="usurp bgStyle"
          v-tooltip="{
            title: $t('studea_manager.triad.usurp.title'),
            position: 'right',
          }"
          @click="switchUser($event, triad.apprentice.id)"
        >
          <FontAwesomeIcon icon="'fa-light fa-user" />
        </button>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-3">
        <router-link
          v-if="triad.tutor"
          :to="{ name: 'profile', params: { id: triad.tutor.cryptedId } }"
        >
          <div class="transactions-list">
            <div>
              <span class="me-2">
                <AvatarComponent :user="triad.tutor" profile="tutor" />
              </span>
              <div class="t-name" v-if="triad.tutor && triad.tutor.fullName">
                <h4>{{ triad.tutor.fullName }}</h4>
                <div
                  class="d-flex align-items-center mt-1"
                  v-if="tutorProfileLabel && tutorProfileLabel.title"
                >
                  <p>{{ tutorProfileLabel.title }}</p>
                  <span
                    v-if="findUserType(2).length"
                    v-tooltip="{ title: this.initTooltipTitle(2) }"
                  >
                    <FontAwesomeIcon
                      icon="fa-light fa-users"
                      class="secondary__user__btn"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
        <router-link
          v-if="triad.tutor2"
          :to="{ name: 'profile', params: { id: triad.tutor2.cryptedId } }"
        >
          <div class="transactions-list mt-3">
            <div>
              <span class="me-2">
                <AvatarComponent :user="triad.tutor2" profile="tutor" />
              </span>
              <div class="t-name">
                <h4>
                  {{ triad.tutor2.firstName }} {{ triad.tutor2.lastName }}
                </h4>
                <p v-if="tutorProfileLabel && tutorProfileLabel.title">
                  {{ tutorProfileLabel.title }}
                </p>
              </div>
            </div>
          </div>
        </router-link>
        <button
          v-if="triad.tutor"
          v-access:[permissions]="{
            access: this.accessManager().ALL,
            code: this.studeaManagerManager().STUDEA_MANAGER_ALLOWED_TO_SWITCH,
          }"
          class="usurp bgStyle"
          v-tooltip="{
            title: $t('studea_manager.triad.usurp.title'),
            position: 'right',
          }"
          @click="switchUser($event, triad.tutor.id)"
        >
          <FontAwesomeIcon icon="'fa-light fa-user" />
        </button>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-3">
        <router-link
          v-if="triad.apprenticeMaster"
          :to="{
            name: 'profile',
            params: { id: triad.apprenticeMaster.cryptedId },
          }"
        >
          <div class="transactions-list">
            <div>
              <span class="me-2">
                <AvatarComponent
                  :user="triad.apprenticeMaster"
                  profile="masterApprentice"
                />
              </span>
              <div
                v-if="triad.apprenticeMaster && triad.apprenticeMaster.fullName"
                class="t-name"
              >
                <h4>{{ triad.apprenticeMaster.fullName }}</h4>
                <div
                  class="d-flex align-items-center mt-1"
                  v-if="
                    apprenticeMasterProfileLabel &&
                    apprenticeMasterProfileLabel.title
                  "
                >
                  <p>{{ apprenticeMasterProfileLabel.title }}</p>
                  <span
                    v-if="
                      triad.activeContract &&
                      triad.activeContract.apprenticeMaster2
                    "
                    v-tooltip="{
                      title:
                        triad.activeContract.apprenticeMaster2.fullName +
                        '\n' +
                        triad.activeContract.apprenticeMaster2.email,
                    }"
                  >
                    <FontAwesomeIcon
                      class="secondary__user__btn"
                      icon="fa-light fa-users"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
        <router-link
          v-if="triad.apprenticeMaster2 && triad.apprenticeMaster2.fullName"
          :to="{ name: 'profile', params: { id: apprenticeMaster2.cryptedId } }"
        >
          <div class="transactions-list mt-2">
            <div>
              <span class="me-2">
                <AvatarComponent
                  :user="triad.apprenticeMaster2"
                  profile="masterApprentice"
                />
              </span>
              <div class="t-name">
                <h4>
                  {{ triad.apprenticeMaster2.fullName }}
                </h4>
                <p>{{ apprenticeMasterProfileLabel.title }} 2</p>
              </div>
            </div>
          </div>
        </router-link>
        <button
          v-if="triad.apprenticeMaster"
          v-access:[permissions]="{
            access: this.accessManager().ALL,
            code: this.studeaManagerManager().STUDEA_MANAGER_ALLOWED_TO_SWITCH,
          }"
          class="usurp bgStyle"
          v-tooltip="{
            title: $t('studea_manager.triad.usurp.title'),
            position: 'right',
          }"
          @click="switchUser($event, triad.apprenticeMaster.id)"
        >
          <FontAwesomeIcon icon="'fa-light fa-user" />
        </button>
      </div>
      <div class="mt-4">
        <router-link
          v-if="triad.companyManager"
          :to="{ name: 'profile', params: { id: companyManager.cryptedId } }"
        >
          <div class="transactions-list">
            <div>
              <span class="me-2">
                <AvatarComponent :user="triad.companyManager" />
              </span>
              <div class="t-name">
                <h4>
                  {{ triad.companyManager.firstName }}
                  {{ triad.companyManager.lastName }}
                </h4>
                <p
                  v-if="
                    companyManagerProfileLabel &&
                    companyManagerProfileLabel.title
                  "
                >
                  {{ companyManagerProfileLabel.title }}
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div
        v-if="
          triad.apprentice &&
          triad.apprentice.parentFirstName &&
          triad.apprentice.parentLastName
        "
        class="mt-4"
      >
        <div class="transactions-list">
          <div>
            <span class="me-2">
              <AvatarComponent
                :name="
                  triad.apprentice.parentFirstName +
                  ' ' +
                  triad.apprentice.parentLastName
                "
              />
            </span>
            <div class="t-name">
              <h4>
                {{ triad.apprentice.parentFirstName }}
                {{ triad.apprentice.parentLastName }}
              </h4>
              <p>{{ $t("Parent") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarComponent from "@/components/utilities/AvatarComponent.vue";
import { mapState } from "pinia";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { userManager } from "@/services/security/user-manager";
import { accessManager } from "@/services/security/access-manager";
import { useUserStore } from "@/store/user/user";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "TriadMemberBlock",
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
      tutorProfileLabel: (store) => store.tutorProfileLabel,
      apprenticeMasterProfileLabel: (store) =>
        store.apprenticeMasterProfileLabel,
      companyManagerProfileLabel: (store) => store.companyManagerProfileLabel,
    }),
  },
  data() {
    return {
      apprentice_progress: 0,
      tutor_progress: 0,
      apprentice_master_progress: 0,
    };
  },
  components: {
    FontAwesomeIcon,
    AvatarComponent,
  },
  methods: {
    studeaManagerManager() {
      return studeaManagerManager;
    },

    accessManager() {
      return accessManager;
    },

    switchUser(e, id) {
      e.stopPropagation();
      studeaManagerManager.switchUser(id);
    },

    findUserType(userType) {
      return userManager.findUserType(userType, this.triad);
    },

    initTooltipTitle(userType) {
      if (this.triad.triadUsers) {
        const user = this.findUserType(userType);
        let title = "";
        if (user.length) {
          user.forEach((user) => {
            if (user.user) {
              title =
                title +
                user.user.fullName +
                " (" +
                (user.triadUserName
                  ? user.triadUserName
                  : user.userType === 1
                    ? this.$t("legal_responsible")
                    : this.$t("Tutor")) +
                ") \n";
            }
          });
          return title;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  height: auto;
}

.transactions-list {
  h4 {
    color: $white;
  }

  p {
    font-weight: 300;
    color: $white;
  }
}

h2 {
  color: var(--primary-color);
}

.usurp {
  background: none;
  border: none;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  color: $white;
  font-size: 18px;
  transition: all 300ms ease-in-out;

  &:hover {
    background: $white;
    color: var(--primary-color);
  }
}

.secondary__user__btn {
  color: $white;
  width: 20px;
  height: 20px;
  margin-left: 15px;
}
</style>
