<template>
  <div class="list__item">{{ index + 1 }}</div>
  <div class="list__item">
    <TriadDuplicatedUserDetailComponent
      :user="duplicateUser.referenceUser"
      :itemToAdd="itemToAdd"
      :referenceUserNotDuplicated="referenceUserNotDuplicated"
      :isKeepUserAlreadySet="isKeepUserAlreadySet"
      @update="updateModels"
    />
  </div>
  <div>
    <div class="mobile__row__title">
      {{ $t("admin.user.duplicated_user") }}
    </div>
    <div class="list__item">
      <TriadDuplicatedUserDetailComponent
        v-for="user in duplicateUser.duplicatedUsers"
        :key="user.id"
        :user="user"
        :itemToAdd="itemToAdd"
        :referenceUserNotDuplicated="referenceUserNotDuplicated"
        :isKeepUserAlreadySet="isKeepUserAlreadySet"
        @update="updateModels"
      />
    </div>
  </div>
  <div class="list__item">
    <div class="mobile__row__title">
      {{ $t("admin.user.options") }}
    </div>
    <div>
      <div class="mb-3">
        {{ duplicateUser.id }}
        <ModelComponent
          v-if="sendBackCredential && Object.keys(sendBackCredential).length"
          :model="sendBackCredential"
          @update="setSendBackCredential"
        />
      </div>
      <ModelComponent
        v-if="
          notDuplicatedCheckoxData &&
          Object.keys(notDuplicatedCheckoxData).length
        "
        :model="notDuplicatedCheckoxData"
        @update="setUserNotDuplicated"
      />
    </div>
  </div>
</template>

<script>
import TriadDuplicatedUserDetailComponent from "@/components/triad/TriadDuplicatedUserDetailComponent.vue";
import ModelComponent from "@/components/form/ModelComponent.vue";

export default {
  name: "TriadDuplicatedUserRowComponent",
  components: {
    ModelComponent,
    TriadDuplicatedUserDetailComponent,
  },
  props: {
    duplicateUser: {
      type: {},
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      referenceUserNotDuplicated: false,
      itemToAdd: {
        userToKeep: null,
        usersToRemove: [],
        referenceNotDuplicated: false,
        sendBackCredential: false,
        usersNotDuplicated: [],
      },
      notDuplicatedCheckoxData: {},
      sendBackCredential: {},
      isKeepUserAlreadySet: false,
    };
  },
  mounted() {
    this.initCheckboxData();
  },
  methods: {
    updateModels(payload) {
      if (payload.keep && this.itemToAdd.userToKeep !== payload.userId) {
        this.searchUserInUserToRemove(payload.userId);
        this.itemToAdd.userToKeep = payload.userId;
        this.isKeepUserAlreadySet = true;
      } else if (payload.delete) {
        if (this.itemToAdd.userToKeep === payload.userId) {
          this.itemToAdd.userToKeep = null;
        }
        if (
          !this.itemToAdd.usersToRemove.find((user) => user === payload.userId)
        ) {
          this.itemToAdd.usersToRemove.push(payload.userId);
        }
      } else if (payload.reset) {
        if (
          this.itemToAdd.usersToRemove.find((user) => user === payload.userId)
        ) {
          this.searchUserInUserToRemove(payload.userId);
        }
        if (this.itemToAdd.userToKeep === payload.userId) {
          this.itemToAdd.userToKeep = null;
        }
        this.isKeepUserAlreadySet = false;
      }
      this.$emit("updateModels", { item: this.itemToAdd, index: this.index });
    },

    searchUserInUserToRemove(userId) {
      const userToAdd = this.itemToAdd.usersToRemove.find(
        (user) => user === userId,
      );
      if (userToAdd) {
        this.itemToAdd.usersToRemove.splice(
          this.itemToAdd.usersToRemove.indexOf(userToAdd),
          1,
        );
      }
    },

    initCheckboxData() {
      this.notDuplicatedCheckoxData = {
        vars: {
          block_prefixes: ["", "switch"],
          label: this.$t("admin.user.reference_user_is_not_duplicate"),
          name: "notDuplicatedUser-" + this.index,
          id: "user_sendBackLogin-" + this.index,
          toggler_target: "useCrewDates",
          value: false,
          required: true,
        },
      };

      this.sendBackCredential = {
        vars: {
          label: this.$t("admin.user.send_credential_back_to_user"),
          name: "sendBackLogin-" + this.index,
          attr: [],
          disabled: false,
          id: "sendBackLogin-" + this.index,
          required: true,
          block_prefixes: ["", "switch"],
        },
      };
    },

    setUserNotDuplicated() {
      this.referenceUserNotDuplicated = !this.referenceUserNotDuplicated;
      if (this.referenceUserNotDuplicated === true) {
        this.itemToAdd.userToKeep = null;
        this.itemToAdd.referenceNotDuplicated = true;
        this.itemToAdd.usersToRemove = [];
        this.itemToAdd.usersNotDuplicated.push(
          this.duplicateUser.referenceUser.id,
        );
        this.duplicateUser.duplicatedUsers.forEach((user) =>
          this.itemToAdd.usersNotDuplicated.push(user.id),
        );
      } else {
        this.itemToAdd.referenceNotDuplicated = false;
        this.itemToAdd.usersNotDuplicated = [];
      }
      this.$emit("userNotDuplicated", {
        item: this.itemToAdd,
        index: this.index,
      });
    },

    setSendBackCredential(value) {
      this.itemToAdd.sendBackCredential = value;
      this.$emit("sendBackCredential", {
        item: this.itemToAdd,
        index: this.index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__row {
    @include up-to-lg {
      flex-direction: column;
    }

    & div {
      width: 100%;

      &:first-child {
        flex-basis: 15%;
      }

      &:last-child {
        flex-basis: 50%;
      }
    }
  }

  &__item {
    padding-right: 8px;

    @include up-to-lg {
      margin-bottom: 30px;
    }

    &:not(:last-child) {
      @include up-to-lg {
        margin-bottom: 20px;
      }
    }
  }
}

.mobile__row__title {
  display: none;
  font-weight: 700;
  font-size: 14px;
  color: $primary;
  padding-bottom: 8px;
  @include up-to-lg {
    display: block;
  }
}
</style>
