<template>
  <div ref="list" :class="'content' + (editing ? ' editing' : '')">
    <div class="left">
      <div class="widget">
        <div class="widget-heading">
          <h2 class="textStyle">
            {{
              $tc("global.list.title", 1, {
                name: $tc("admin.modulet.title", 2),
              })
            }}
          </h2>
        </div>
        <div class="widget-content">
          <div class="panel-body">
            <div class="parent ex-1">
              <VueDraggableNext
                :animation="200"
                :delay="200"
                :delay-on-touch-only="true"
                :list="modules"
                :sort="!editing"
                :disabled="
                  !accessManager().isAvailableForUser(accessManager().EDIT)
                "
                @change="sort"
              >
                <div
                  v-for="item in modules"
                  :key="item.id"
                  :class="
                    'd-md-flex d-block text-sm-start text-center item' +
                    (editItem.id === item.id ? ' active' : '') +
                    (editing ? ' editing' : '')
                  "
                >
                  <div class="media-body">
                    <div
                      class="d-flex d-block justify-content-between align-items-center flex-column flex-md-row"
                    >
                      <div class="item__heading">
                        <span
                          :class="
                            'dot bg-' + (item.enabled ? 'success' : 'danger')
                          "
                        ></span>
                        <FontAwesomeIcon
                          v-if="item.icon"
                          :icon="'fa-light fa-' + item.icon"
                          class="item__icon"
                        />
                        <div class="item__title">{{ item.name }}</div>
                      </div>
                      <div v-if="!editing" class="mt-3 mt-md-0">
                        <router-link
                          v-if="isConfig(item)"
                          :to="configPath(item)"
                          class="btn btn-info me-2"
                          v-tooltip="{
                            title:
                              $t('admin.modulet.config.title') +
                              ' ' +
                              item.name,
                          }"
                          type="button"
                          v-access:[permissions]="accessManager().EDIT"
                        >
                          <FontAwesomeIcon
                            class="item__editIcon"
                            icon="fa-light fa-gear"
                          />
                        </router-link>
                        <router-link
                          v-if="item.enabled"
                          :to="{
                            name: 'adminModuleTrainingCourses',
                            params: { id: item.id },
                          }"
                          class="btn btn-primary me-2"
                          v-tooltip="{
                            title:
                              $t('admin.modulet.trainingCourse.edit.title') +
                              ' ' +
                              item.name,
                          }"
                          v-access:[permissions]="accessManager().EDIT"
                          type="button"
                        >
                          <FontAwesomeIcon
                            class="item__editIcon"
                            icon="fa-light fa-list"
                          />
                        </router-link>
                        <button
                          v-tooltip="{
                            title: $tc('global.edit.title', 1, {
                              name: this.$tc(
                                'admin.modulet.title',
                                1,
                              ).toLowerCase(),
                            }),
                          }"
                          v-access:[permissions]="accessManager().EDIT"
                          class="btn btn-secondary btnStyle"
                          type="button"
                          @click="edit($event, item)"
                        >
                          <FontAwesomeIcon
                            class="item__editIcon"
                            icon="fa-light fa-pen"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </VueDraggableNext>
            </div>
          </div>
        </div>
        <BlockLoader :loading="listLoading" />
      </div>
    </div>
    <div ref="edit" class="right">
      <div :class="'widget' + (editing ? ' editing' : ' closed')">
        <div class="widget-heading">
          <h2 class="textStyle">
            {{
              $tc("global.edit.title", 1, {
                name: this.$tc("admin.modulet.title", 1).toLowerCase(),
              })
            }}
            {{ editItem.name }}
          </h2>
        </div>
        <div class="widget-content">
          <FormComponent
            v-if="Object.keys(formModels).length"
            :models="formModels"
            :standAlone="true"
            @formCancel="close"
            @formSubmit="valid"
          />
          <BlockLoader :loading="loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FormComponent from "@/components/FormComponent.vue";
import { notificationManager } from "@/services/utilities/notification-manager";
import { adminManager } from "@/services/admin/admin-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { VueDraggableNext } from "vue-draggable-next";
import { formManager } from "@/services/form/form-manager";
import { formValidator } from "@/services/form/form-validator";
import { accessManager } from "@/services/security/access-manager";
import { useAdminStore } from "@/store/admin/admin";
import { useUserStore } from "@/store/user/user";

export default {
  name: "ModulesListView",
  components: {
    VueDraggableNext,
    BlockLoader,
    FormComponent,
    FontAwesomeIcon,
  },
  data() {
    return {
      editItem: [],
      editing: false,
      formModels: [],
      loading: false,
      listLoading: false,
    };
  },
  computed: {
    ...mapState(useAdminStore, {
      modules: (store) => store.modules,
    }),
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  mounted() {
    this.getModules();
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    valid() {
      let datas = formManager.processForm(this.formModels, false);
      adminManager.putModule(datas, this.editItem.id).then((res) => {
        if (res && !res.hasError) {
          notificationManager.showNotification(
            "success",
            this.$tc("global.edit.success", 1, {
              name: this.$tc("admin.modulet.title", 1),
            }),
          );
          this.fetchModules();
          this.close();
        } else if (res.responses && res.responses[0].validationErrors) {
          notificationManager.showNotification(
            "error",
            this.$t("signed_error"),
          );
          formValidator.processResponses(
            res.responses[0].validationErrors,
            this.models,
          );
        }
      });
    },

    edit(e, item) {
      e.stopPropagation();
      this.editItem = item;
      this.getForm(item.id);
      this.editing = true;
      this.scrollToForm();
    },

    close() {
      this.editItem = [];
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.editing = false;
    },

    sort(event) {
      if (!this.editing) {
        adminManager
          .sortModule(event.moved.element.id, event.moved.newIndex)
          .then((res) => {
            if (res) {
              notificationManager.showNotification(
                "success",
                this.$tc("global.sort.success", 1, {
                  name: this.$tc("admin.modulet.title", 1).toLowerCase(),
                }),
              );
            } else {
              notificationManager.showNotification(
                "error",
                this.$t("signed_error"),
              );
            }
          });
      }
    },

    getModules() {
      this.listLoading = true;
      this.fetchModules().then(() => {
        this.listLoading = false;
      });
    },

    isConfig(module) {
      const configable = [
        "MODULE_STUDEA_ABSENCE",
        "MODULE_STUDEA_QUESTIONNAIRE",
        "MODULE_YPAREO",
      ];
      return configable.includes(module.code);
    },

    configPath(module) {
      let path = "";
      switch (module.code) {
        case "MODULE_STUDEA_ABSENCE":
          path = { name: "adminModuleAbsenceConfig" };
          break;
        case "MODULE_STUDEA_QUESTIONNAIRE":
          path = {
            name: "adminModuleQuestionnaireConfig",
            params: { alias: "questionnaire-config" },
          };
          break;
        case "MODULE_YPAREO":
          path = {
            name: "adminModuleYpareoConfig",
            params: { alias: "ypareo_config" },
          };
      }
      return path;
    },

    getForm(id) {
      this.formModels = [];
      this.loading = true;

      adminManager.form("module", id).then((form) => {
        this.formModels = form;
        this.loading = false;
      });
    },

    scrollToForm() {
      setTimeout(() => {
        window.scrollTo({
          behavior: "smooth",
          top:
            this.$refs.edit.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            250,
        });
      }, 50);
    },

    ...mapActions(useAdminStore, ["fetchModules"]),
  },
};
</script>

<style lang="scss" scoped>
.widget {
  transition: all 300ms ease-in-out;

  &.padding {
    padding: 20px;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include up-to-lg {
    flex-direction: column;
  }

  .left {
    width: 100%;
    transition: all 300ms ease-out;
  }

  .right {
    width: 0;
    transition: all 300ms ease-out;
    overflow: hidden;
  }

  &.editing {
    .left {
      width: 66%;

      @include up-to-lg {
        width: 100%;
      }
    }

    .right {
      margin-left: 20px;
      width: 33%;
      @include up-to-lg {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

.item {
  padding: 5px 25px;
  border: 1px solid $grey;
  border-radius: $radius;
  min-height: 50px;
  align-items: center;
  margin-bottom: 10px;
  cursor: grab;

  &.editing {
    cursor: initial;
  }

  &.active {
    background: $light-blue;

    @include dark-theme {
      background: $dark-grey;
    }
  }

  @include up-to-md {
    padding: 15px;
  }

  &__heading {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 16px;
    color: $admin-color;

    @include mobile {
      max-width: 195px;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }

    @include dark-theme {
      color: $white;
    }
  }

  &__icon {
    margin-right: 15px;
    font-size: 22px;

    @include up-to-md {
      margin-right: 10px;
      font-size: 18px;
    }
  }

  &__editIcon {
    width: 15px;
    height: 15px;
  }
}

.parent.ex-1 .drag-drop {
  @include up-to-md {
    padding: 0;
  }
}

.widget-content {
  min-height: 200px;
  position: relative;
}
</style>
