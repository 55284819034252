<template>
  <div
    ref="messengerMessageModal"
    aria-hidden="true"
    aria-labelledby="messengerMessageModal"
    class="modal show fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title textStyle">
            {{ $t("header.notification.list") }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div class="modal-body">
          <ul class="message-list">
            <NotificationComponent
              :message="message"
              v-for="(message, index) in list"
              :key="message.id"
              :separator="index"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { mapActions, mapState } from "pinia";
import { useNotificationStore } from "@/store/notifications/notification";
import NotificationComponent from "@/components/notifications/NotificationComponent.vue";

export default {
  name: "NotificationListModalComponent",
  components: { NotificationComponent },
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useNotificationStore, {
      listOpened: (store) => store.listOpened,
      list: (store) => store.list,
    }),
  },
  watch: {
    listOpened() {
      const container = this.$refs.messengerMessageModal;
      const modal = new Modal(container);
      if (this.listOpened) {
        modal.show();
      } else {
        modal.hide();
        this.setListOpened(false);
      }
    },
  },
  mounted() {
    this.$refs.messengerMessageModal.addEventListener(
      "hidden.bs.modal",
      this.hidden,
    );
  },
  methods: {
    hidden() {
      this.$emit("hidden");
      const container = this.$refs.messengerMessageModal;
      container.removeEventListener("click", this.stop);
      this.setListOpened(false);
    },

    stop(e) {
      e.stopPropagation();
    },

    ...mapActions(useNotificationStore, ["setListOpened"]),
  },
};
</script>

<style lang="scss" scoped></style>
