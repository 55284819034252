<template>
  <div>
    <FormTabsBlockComponent
      :tabs="tabs"
      @formCancel="cancel"
      @formSubmit="submit"
      :loading="loading"
      :invalidTab="invalidTab"
    />
  </div>
</template>

<script>
import FormTabsBlockComponent from "@/components/blocks/FormTabsBlockComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";

export default {
  name: "SkillAddSessionReferentialView",
  components: { FormTabsBlockComponent },

  data() {
    return {
      loading: false,
      tabs: [],
      invalidTab: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      studeaManagerManager
        .getReferentialSessionForm(this.$route.params.trainingCourseId)
        .then((response) => {
          this.loading = false;
          this.tabs = response.tabs;
        });
    },

    submit() {
      let response = [];
      this.loading = true;
      this.invalidTab = null;
      Object.values(this.tabs).forEach((tab) => {
        if (!tab.hidden) {
          response.push({
            form: tab.models ? formManager.processForm(tab.models, false) : [],
            code: tab.code,
          });
        }
      });
      studeaManagerManager
        .postReferentialSession(
          this.$route.params.trainingCourseId,
          this.$route.query.currentSession,
          response,
        )
        .then((response) => {
          if (response.success) {
            this.$router.push({
              name: "studeaManagerSkillList",
            });
          }
        });
    },

    cancel() {
      this.$router.push({ name: "studeaManagerSkillList" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
