<template>
  <div class="sidebar-wrapper sidebar-theme">
    <nav id="sidebar" ref="menu">
      <div class="shadow-bottom"></div>

      <perfect-scrollbar
        :options="{
          wheelSpeed: 0.5,
          swipeEasing: !0,
          minScrollbarLength: 40,
          maxScrollbarLength: 300,
          suppressScrollX: true,
        }"
        class="list-unstyled menu-categories"
        tag="ul"
      >
        <li class="menu">
          <router-link
            :class="
              'textStyle dropdown-toggle' +
              ($route.name === (admin ? 'adminHome' : 'studeaManagerHome')
                ? ' active'
                : '')
            "
            :to="
              admin
                ? { name: 'adminHome', query: { time: Date.now() } }
                : {
                    name: 'studeaManagerHome',
                    params: {
                      trainingCourseId: this.$route.params.trainingCourseId,
                    },
                    query: { time: Date.now() },
                  }
            "
            @click="click"
          >
            <div>
              <FontAwesomeIcon class="icontStyle" icon="fa-light fa-house" />
              <span>{{ $t("page.home") }}</span>
            </div>
          </router-link>
        </li>
        <template v-for="item in menu" :key="item.id">
          <li
            class="menu"
            v-if="!item.params || (item.params && !item.params.notVisible)"
          >
            <router-link
              v-if="item.pathName"
              :class="
                'dropdown-toggle textStyle' +
                ($route.matched.find((entry) => entry.name === item.pathName)
                  ? ' active'
                  : '')
              "
              :to="{
                name: item.pathName,
                params: item.params,
                query: { time: Date.now() },
              }"
              @click="click"
            >
              <div class="">
                <FontAwesomeIcon
                  :icon="'fa-light fa-' + item.icon"
                  class="icontStyle"
                />
                <span>{{ $t(item.title) }}</span>
              </div>
            </router-link>
            <a
              v-else
              :aria-controls="'toggle-' + item.id"
              :aria-expanded="active(item)"
              :data-bs-target="'#toggle-' + item.id"
              class="dropdown-toggle textStyle"
              data-bs-toggle="collapse"
            >
              <div class="">
                <FontAwesomeIcon
                  :icon="'fa-light fa-' + item.icon"
                  class="icontStyle"
                />
                <span>{{ $t(item.title) }}</span>
              </div>
              <div>
                <svg
                  class="feather feather-chevron-right"
                  fill="none"
                  height="24"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </div>
            </a>

            <ul
              v-if="
                item.children &&
                item.children.items &&
                Object.keys(item.children.items).length
              "
              :id="'toggle-' + item.id"
              :class="
                'collapse submenu list-unstyled' + (active(item) ? ' show' : '')
              "
              data-bs-parent="#sidebar"
            >
              <template v-for="child in item.children.items" :key="child.id">
                <!--      TODO child.id !== 'tableau-des-notes' && child.id !== 'tableau-de-bord-des-competences' when the dev will be done            -->
                <li
                  v-if="
                    (child.id !== 'tableau-des-notes' &&
                      child.id !== 'tableau-de-bord-des-competences' &&
                      !child.params) ||
                    (child.params && !child.params.notVisible)
                  "
                >
                  <router-link
                    v-if="!child.disabled && moduleHasPage(child)"
                    :class="
                      'textStyle' + (active(child, true) ? ' active' : '')
                    "
                    :to="{
                      name: child.pathName,
                      params: child.params,
                      query: { time: Date.now() },
                    }"
                    @click="click"
                  >
                    {{ child.title }}
                  </router-link>
                  <div v-else class="disabled">
                    {{ child.title }}
                  </div>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </perfect-scrollbar>
    </nav>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapActions } from "pinia";
import { useMainStore } from "@/store/main/main";

export default {
  name: "SideMenuComponent",
  components: { FontAwesomeIcon },
  props: {
    menu: {
      type: [],
      required: true,
      default: [],
    },
    admin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    active(item, child = false) {
      let ret = false;
      Object.values(this.$route.matched).forEach((match) => {
        if (!child && !ret) {
          if (
            item.children &&
            item.children.items &&
            Object.keys(item.children.items).length > 0
          ) {
            Object.values(item.children.items).forEach((child) => {
              if (!ret) {
                ret = this.isActive(match, child);
              }
            });
          }
        } else {
          if (!ret) {
            ret = this.isActive(match, item);
          }
        }
      });
      return ret;
    },

    isActive(match, item) {
      return match.meta.generic && !match.meta.code
        ? item.params && this.$route.params.alias === item.params.alias
        : match.name === item.pathName;
    },

    click() {
      if (window.innerWidth < 992) {
        this.closeSideBar();
      }
    },

    moduleHasPage(item) {
      return ![
        "STUDEA_MANAGER_NOTEPAD",
        "STUDEA_MANAGER_REGISTRATION",
        "STUDEA_MANAGER_NOTE",
      ].includes(item.access);
    },

    ...mapActions(useMainStore, ["closeSideBar"]),
  },
};
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
  width: 212px;
  z-index: 1028;
  border-radius: 6px 6px 0 0;
  transition: 0.6s;
  height: 100vh;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 106px;
  left: 0;
  padding-left: 16px;

  @include dark-theme {
    background: $m-color_10;
  }

  @include up-to-lg {
    left: 0;
    padding-left: 0;
    top: 95px;
    width: 100%;
  }
}

#sidebar {
  ul.menu-categories {
    position: relative;
    margin: auto;
    width: 100%;
    height: calc(100vh - 127px) !important;
    overflow: hidden;

    &.ps {
      height: calc(100vh - 107px) !important;
      margin-right: 0;
      padding-right: 18px;
      border-right: 1px solid #e0e6ed;
    }
  }

  .ps__thumb-y {
    right: 4px;
  }

  ul.menu-categories {
    li {
      > .dropdown-toggle {
        &[data-active="true"] svg.feather-chevron-right,
        &[aria-expanded="true"] svg.feather-chevron-right {
          transform: rotate(90deg);
        }
      }

      &.menu {
        &:first-child {
          ul.submenu > li a {
            justify-content: flex-start;

            i {
              align-self: center;
              margin-right: 12px;
              font-size: 19px;
              width: 21px;
            }
          }

          > .dropdown-toggle {
            margin-top: 21px;
          }
        }

        > .dropdown-toggle {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          font-size: 13px;
          color: #0e1726;
          padding: 11px 14px;
          transition: 0.6s;
          margin-bottom: 4px;
          font-weight: 600;
          letter-spacing: 1px;

          > div {
            align-self: center;
          }
        }

        .dropdown-toggle:after {
          display: none;
        }

        > {
          .dropdown-toggle {
            svg {
              color: #506690;
              margin-right: 14px;
              vertical-align: middle;
              width: 20px;
              height: 20px;
              stroke-width: 1.6;

              @include dark-theme {
                color: $white;
              }
            }

            &[data-active="true"] {
              background: #fff !important;
              box-shadow:
                0 1px 3px 0 rgba(0, 0, 0, 0.1),
                0 1px 2px 0 rgba(0, 0, 0, 0.06);
              border-radius: 6px;
              color: #0e1726;

              @include dark-theme {
                background: $dark-grey !important;
              }

              svg {
                color: #030305;
                fill: #e0e6ed;

                @include dark-theme {
                  color: $white;
                }
              }

              &:hover svg,
              span {
                color: #030305;

                @include dark-theme {
                  color: $white;
                }
              }
            }

            &:hover,
            &.dropdown-toggle:not([data-active="true"]):not(
                [aria-expanded="true"]
              ):hover {
              background: #fff;
              box-shadow:
                0 1px 3px 0 rgba(0, 0, 0, 0.1),
                0 1px 2px 0 rgba(0, 0, 0, 0.06);
              border-radius: 6px;

              @include dark-theme {
                background: $dark-grey;
              }
            }

            &[aria-expanded="true"]:not([data-active="true"]) {
              background: #fff;
              box-shadow:
                0 1px 3px 0 rgba(0, 0, 0, 0.1),
                0 1px 2px 0 rgba(0, 0, 0, 0.06);
              border-radius: 6px;
              color: #0e1726;

              @include dark-theme {
                background: $dark-grey;
              }
            }

            &:hover svg {
              color: #030305;

              @include dark-theme {
                color: $white;
              }
            }

            &[aria-expanded="true"]:not([data-active="true"]) svg {
              color: #030305;
              fill: #e0e6ed;

              @include dark-theme {
                color: $white;
              }
            }

            svg.feather-chevron-right {
              vertical-align: middle;
              margin-right: 0;
              width: 15px;
            }

            &[data-active="true"] svg {
              &.flaticon-right-arrow,
              &.flaticon-down-arrow {
                background-color: transparent;
              }
            }
          }

          a span:not(.badge) {
            vertical-align: middle;
          }
        }

        > a.active {
          background: #fff;
          box-shadow:
            0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 0 rgba(0, 0, 0, 0.06);
          border-radius: 6px;
          color: #0e1726;

          @include dark-theme {
            background: $dark-grey;
          }

          svg {
            color: #030305;
            fill: #e0e6ed;

            @include dark-theme {
              color: $white;
            }
          }

          &:hover svg,
          span {
            color: #030305;

            @include dark-theme {
              color: $white;
            }
          }
        }
      }
    }

    ul.submenu > li a,
    ul.submenu > li div.disabled {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 10px 12px 10px 48px;
      padding-left: 24px;
      margin-left: 10px;
      font-size: 13px;
      color: #515365;
    }

    li.menu ul.submenu > li a:before {
      content: "";
      background-color: #d3d3d3;
      position: absolute;
      height: 3px;
      width: 3px;
      top: 17.5px;
      left: 13px;
      border-radius: 50%;

      @include dark-theme {
        background-color: $white !important;
      }
    }

    ul.submenu > li {
      a.active {
        color: #4361ee;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        font-weight: 600;
      }

      a.dropdown-toggle {
        padding: 10px 15px 10px 24px;
      }
    }

    li.menu ul.submenu > li a.active:before {
      background-color: #4361ee;

      @include dark-theme {
        background-color: $white;
      }
    }

    ul.submenu {
      > li {
        a {
          &.active {
            position: relative;
          }

          &:hover {
            color: #4361ee;

            @include dark-theme {
              color: $white;
            }

            &:before {
              background-color: #4361ee !important;

              @include dark-theme {
                background-color: $white;
              }
            }
          }

          i {
            align-self: center;
            font-size: 9px;
          }
        }
      }

      li > {
        [data-active="true"] {
          i {
            color: #4361ee;
          }

          &:before {
            background-color: #4361ee !important;
          }
        }

        a[data-active="true"] {
          color: #4361ee;
        }
      }

      > li ul.sub-submenu > li {
        a {
          position: relative;
          padding: 10px 12px 10px 48px;
          padding-left: 13px;
          margin-left: 47px;
          font-size: 12px;
          color: #515365;
        }

        a.active {
          color: #805dca;

          &:before {
            background-color: #4361ee;
          }
        }

        a {
          &:hover {
            color: #4361ee;
          }

          &:before {
            display: none;
          }
        }

        // &.active a:before {
        //     background-color: #4361ee;
        // }
      }
    }
  }
}
</style>
