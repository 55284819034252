import { defineStore } from "pinia";
import {
  adminStore,
  platformStore,
  questionnaireStore,
  studeaManagerStore,
  trainingCourseStore,
  triadStore,
  userStore,
} from "@/main";

export const useMainStore = defineStore({
  id: "Main",
  state: () => {
    return {
      appWebVersion: null,
      appBuildVersion: null,
      isInternet: navigator.onLine,
      isCheckingInternet: false,
      isMobile: false,
      appLoading: false,
      fileDownloading: false,
      saving: false,
      trainingCourseMenu: [],
      breadcrumb: [],
      sideBarOpened: window.innerWidth > 768,
      helpDocuments: [],
      showHelpDocuments: false,
      windowWidth: 1920,
      callingPath: null,
      callingMethod: null,
      maintenance: false,
      trainingCoursePilotage: [
        {
          title: "Tableau de bord",
          pathName: "trainingCourseMessaging",
        },
        {
          title: "Tableau des notes",
          pathName: "trainingCourseMessaging",
        },
        {
          title: "Tableau de bord compétences",
          pathName: "trainingCourseMessaging",
        },
        {
          title: "Exports",
          pathName: "trainingCourseMessaging",
        },
        {
          title: "Suivi des mails",
          pathName: "trainingCourseMessaging",
        },
        {
          title: "Historique de connexion",
          pathName: "trainingCourseMessaging",
        },
      ],
    };
  },
  actions: {
    setMobileApp(value) {
      this.isMobile = value;
    },

    setAppBuildVersion(value) {
      this.appBuildVersion = value;
    },

    setAppWebVersion(value) {
      this.appWebVersion = value;
    },

    setAppLoading(value) {
      this.appLoading = value;
    },

    setMaintenance(value) {
      this.maintenance = value;
    },

    setCallingPath(value) {
      this.callingPath = value;
    },

    setCallingMethod(value) {
      this.callingMethod = value;
    },

    setShowHelpDocuments(value) {
      this.showHelpDocuments = value;
    },

    setHelpDocuments(value) {
      this.helpDocuments = value;
    },

    savingRequest() {
      this.saving = true;
    },

    setBreadcrumb(breadcrumb) {
      this.breadcrumb = breadcrumb;
    },

    setWindowWidth(width) {
      this.windowWidth = width;
    },

    savingSuccess() {
      this.saving = false;
    },

    fileDownloadRequest() {
      this.fileDownloading = true;
    },

    setInternet(value) {
      this.isInternet = value;
    },

    fileDownloadSuccess() {
      this.fileDownloading = false;
    },

    closeSideBar() {
      this.sideBarOpened = false;
    },

    openSideBar() {
      this.sideBarOpened = true;
    },

    toggleSideBar() {
      this.sideBarOpened = !this.sideBarOpened;
    },

    initStore() {
      questionnaireStore.init();
      trainingCourseStore.init();
      userStore.init();
      platformStore.init();
      triadStore.init();
      studeaManagerStore.init();
      adminStore.init();
    },
  },
});
