<template>
  <div :class="'indicator__container ' + (subIndicatorActive ? '-active' : '')">
    <div class="d-flex mb-1 justify-content-between align-items-center">
      <span class="textStyle">{{
        data && data.label ? data.label : $t("dashboard.indicators.fill_rate")
      }}</span>
      <button
        v-if="data.subIndicators && data.subIndicators.length"
        class="btn-icon iconStyle"
        @click="toggleSubIndicatorActive"
        v-tooltip="{ title: $t('dashboard.indicators.see_details') }"
      >
        <FontAwesomeIcon
          :icon="'fa-light fa-' + (subIndicatorActive ? 'eye-slash' : 'eye')"
        />
      </button>
    </div>
    <StatisticBarBlockComponent :data="data" />
  </div>
  <div
    v-if="data.subIndicators && data.subIndicators.length"
    :class="subIndicatorActive ? 'mb-4' : ''"
  >
    <div v-for="(subIndicator, index) in data.subIndicators" :key="index">
      <div
        v-for="(item, index) in subIndicator.indicators"
        :key="index"
        :class="'subIndicator ' + (subIndicatorActive ? '-active' : '')"
      >
        <span class="textStyle">
          {{ item.label }}
        </span>
        <StatisticBarBlockComponent :data="item" />
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import StatisticBarBlockComponent from "@/components/blocks/statistic/StatisticBarBlockComponent.vue";

export default {
  name: "StatisticBlockComponent",
  components: { StatisticBarBlockComponent, FontAwesomeIcon },
  props: {
    data: {
      type: [],
      required: true,
    },
  },
  data() {
    return {
      subIndicatorActive: false,
    };
  },
  methods: {
    toggleSubIndicatorActive() {
      this.subIndicatorActive = !this.subIndicatorActive;
    },
  },
};
</script>

<style lang="scss" scoped>
.indicator__container {
  padding: 10px;
  border-radius: 6px;

  &.-active {
    box-shadow:
      0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.06);
    background-color: #f5f5fe;
  }
}

.subIndicator {
  padding-left: 10px;
  padding-right: 10px;
  height: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: all 300ms ease-in-out;

  &.-active {
    padding-top: 10px;
    transform: scaleY(100%);
    height: 50px;

    &:last-child {
      height: 70px;
    }
  }
}
</style>
