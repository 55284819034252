<template :key="key">
  <div
    class="widget mb-3"
    :class="{ sticky: front, isProgressBar: isProgressBar }"
  >
    <div class="widget-heading border-0">
      <div
        v-if="!editTitle"
        class="d-flex justify-content-center align-items-center titleBlock"
        @click="toggleTitle(true, $event)"
      >
        <h1 v-if="!front" class="textStyle editable">
          {{
            questionnaire.title
              ? questionnaire.title
              : $t("studea_manager.questionnaire.untitled_questionnaire")
          }}
          {{ saved ? "" : "*" }}
        </h1>
        <h1 v-else class="textStyle">{{ formTitle }}</h1>
        <FontAwesomeIcon
          v-if="!front"
          class="ms-2 editable"
          icon="fa-light fa-pen"
        />
      </div>
      <form
        v-else
        class="d-flex align-items-center titleBlock"
        @click="$event.stopPropagation()"
      >
        <ModelComponent :model="title" @update="updateTitle" />
        <button
          class="btn btnStyle ms-2 btn-success toggleTitle"
          type="submit"
          @click="toggleTitle(false, $event)"
        >
          <FontAwesomeIcon icon="fa-light fa-check" />
        </button>
      </form>
      <div :key="key" class="dates mt-2" v-html="openingDates"></div>
      <div v-if="questionnaire.message" class="badge bg-danger mt-3">
        {{ questionnaire.message }}
      </div>
      <div class="active" v-if="!front">
        {{ $t("studea_manager.questionnaire.active") }}
        <SwitchTypeComponent
          :key="key"
          :model="active"
          @update="updateActive"
        />
      </div>
    </div>
    <div class="widget-content fw-bold" v-if="!front">
      {{ count }}
      {{ $tc("studea_manager.questionnaire.question", count > 1 ? 2 : 1) }}
    </div>
  </div>
  <div class="widget mb-3">
    <div
      v-if="questionnaire.description || !front || pdfSrc"
      class="widget-content"
    >
      <div
        v-if="!editDescription"
        :class="!front ? 'editable' : ''"
        class="toggleDescription"
        @click="toggleDescription(true, $event)"
      >
        <h2 v-if="questionnaire.description || !front" class="mb-3 textStyle">
          {{ $t("description") }}
          <FontAwesomeIcon v-if="!front" class="ms-2" icon="fa-light fa-pen" />
        </h2>
        <div
          v-if="questionnaire.description"
          class="description"
          v-html="questionnaire.description"
        ></div>
      </div>
      <form
        v-else
        class="d-flex align-items-center flex-column flex-md-row"
        @click="$event.stopPropagation()"
      >
        <ModelComponent :model="description" @update="updateDescription" />
        <button
          class="btn btnStyle ms-md-2 mt-3 mt-md-0 btn-success toggleDescription"
          type="submit"
          @click="toggleDescription(false, $event)"
        >
          <FontAwesomeIcon icon="fa-light fa-check" />
        </button>
      </form>
      <iframe v-if="pdfSrc" :src="pdfSrc" class="descriptionPdf"></iframe>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import ModelComponent from "@/components/form/ModelComponent.vue";
import { dateFormatter } from "@/services/utilities/date-formatter";
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { useUserStore } from "@/store/user/user";
import { useTriadStore } from "@/store/triad/triad";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";
import { useStudeaManagerQuestionnaireStore } from "@/store/studea_manager/studea-manager-questionnaire";

export default {
  name: "QuestionnaireHeadingComponent",
  components: { SwitchTypeComponent, ModelComponent },
  props: {
    front: {
      type: Boolean,
      required: false,
      default: false,
    },
    formTitle: {
      type: String,
      required: false,
      default: "",
    },
    pdfSrc: {
      type: String,
      required: false,
      default: "",
    },
    isProgressBar: {
      type: Boolean,
      required: false,
      default: false,
    },
    editTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    editDescription: {
      type: Boolean,
      required: false,
      default: false,
    },
    saved: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      itemModel: this.model,
      questionnaire: [],
      crews: {},
      key: 0,
      hideDescription: false,
    };
  },
  watch: {
    questionnaireEditor() {
      this.questionnaire = this.questionnaireEditor;
      this.key++;
    },
    questionnaireFront() {
      this.questionnaire = this.questionnaireFront;
      this.key++;
    },
  },
  mounted() {
    this.questionnaire = this.front
      ? this.questionnaireFront
      : this.questionnaireEditor;
    this.setCrews();
  },
  computed: {
    title() {
      return {
        vars: {
          value: this.questionnaire.title,
          block_prefixes: ["", "text"],
          name: "questionnaire-title",
          id: "questionnaire-title",
          label: false,
          attr: {
            placeholder: this.$t(
              "studea_manager.questionnaire.untitled_questionnaire",
            ),
          },
        },
      };
    },

    count() {
      if (
        this.questionnaireEditor.questions &&
        Object.keys(this.questionnaireEditor.questions).length
      ) {
        let count = 0;
        Object.values(this.questionnaireEditor.questions).forEach(
          (question) => {
            if (question.itemType === "field") {
              count++;
            } else if (
              question.itemType === "cluster" &&
              question.displayMode === 2 &&
              question.questions &&
              Object.keys(question.questions).length
            ) {
              count += Object.keys(question.questions).length;
            }
          },
        );
        return count;
      }
      return 0;
    },

    active() {
      return {
        vars: {
          value: this.questionnaire.active,
          block_prefixes: ["", "switch"],
          name: "questionnaire-active",
          id: "questionnaire-active",
          label: false,
        },
      };
    },

    description() {
      return {
        vars: {
          value: this.questionnaire.description,
          block_prefixes: ["", "text", "textarea", "zama_tiny_mce_type"],
          name: "questionnaire-description",
          id: "questionnaire-description",
          label: false,
          attr: {
            placeholder: this.$t(
              "studea_manager.questionnaire.untitled_questionnaire",
            ),
          },
          conf_name: "training_course",
          conf_path: "questionnaire",
        },
      };
    },

    openingDates() {
      let dates = "";

      if (
        this.questionnaire.signedBy &&
        Object.keys(this.questionnaire.signedBy).length &&
        !this.questionnaire.useCrewDates
      ) {
        Object.values(this.questionnaire.signedBy).forEach((sign) => {
          if (
            (!this.front && sign.checked) ||
            (this.front &&
              this.triad.userRoles &&
              sign === this.triad.userRoles[0])
          ) {
            let start = "";
            let end = "";
            let trad = "";
            switch (this.front ? sign : sign.data) {
              case 1:
                if (
                  this.questionnaire.defaultApprenticeAccessStartDate &&
                  this.questionnaire.defaultApprenticeAccessEndDate
                ) {
                  start = this.questionnaire.defaultApprenticeAccessStartDate;
                  end = this.questionnaire.completenessDate
                    ? this.questionnaire.completenessDate
                    : this.questionnaire.defaultApprenticeAccessEndDate;
                  trad = "apprentices";
                }
                break;
              case 2:
                if (
                  this.questionnaire.defaultTutorAccessStartDate &&
                  this.questionnaire.defaultTutorAccessEndDate
                ) {
                  start = this.questionnaire.defaultTutorAccessStartDate;
                  end = this.questionnaire.completenessDate
                    ? this.questionnaire.completenessDate
                    : this.questionnaire.defaultTutorAccessEndDate;
                  trad = "tutors";
                }
                break;
              case 3:
                if (
                  this.questionnaire.defaultApprenticeMasterAccessStartDate &&
                  this.questionnaire.defaultApprenticeMasterAccessEndDate
                ) {
                  start =
                    this.questionnaire.defaultApprenticeMasterAccessStartDate;
                  end = this.questionnaire.completenessDate
                    ? this.questionnaire.completenessDate
                    : this.questionnaire.defaultApprenticeMasterAccessEndDate;
                  trad = "apprentice_masters";
                }
                break;
              case 4:
                if (
                  this.questionnaire.defaultStudeaManagerAccessStartDate &&
                  this.questionnaire.defaultStudeaManagerAccessEndDate
                ) {
                  start =
                    this.questionnaire.defaultStudeaManagerAccessStartDate;
                  end = this.questionnaire.completenessDate
                    ? this.questionnaire.completenessDate
                    : this.questionnaire.defaultStudeaManagerAccessEndDate;
                  trad = "studeaManagers";
                }
                break;
            }

            if (start && end && trad) {
              dates = dates.concat(
                this.$t("open_from") +
                  " " +
                  dateFormatter.format(start) +
                  " - " +
                  dateFormatter.format(end) +
                  (this.front ? "" : " (" + this.$t(trad) + ")<br>"),
              );
            }
          }
        });
      }

      if (
        this.questionnaire &&
        this.questionnaire.useCrewDates &&
        Object.keys(this.crews).length
      ) {
        Object.values(this.crews).forEach((crew) => {
          if (crew.startDate && crew.endDate) {
            dates = dates.concat(
              this.$t("open_from") +
                " " +
                dateFormatter.format(crew.startDate) +
                " - " +
                dateFormatter.format(crew.endDate) +
                (this.front ? "" : " (" + crew.title + ")<br>"),
            );
          } else {
            dates = dates.concat(
              this.$t("no_crew_dates") + " (" + crew.title + ")<br>",
            );
          }
        });
      }
      return dates;
    },

    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useQuestionnaireStore, {
      questionnaireFront: (store) => store.questionnaire,
    }),
    ...mapState(useStudeaManagerQuestionnaireStore, {
      questionnaireEditor: (store) => store.questionnaire,
    }),
  },
  methods: {
    updateTitle(value) {
      this.questionnaire.title = value;
      this.$emit("update");
    },

    updateActive(value) {
      this.questionnaire.active = value;
      this.$emit("update");
    },

    updateDescription(value) {
      this.questionnaire.description = value;
      this.$emit("update");
    },

    toggleTitle(value, e) {
      e.stopPropagation();
      this.$emit("toggleTitle", value);
    },

    setCrews() {
      if (!this.front && this.$route) {
        studeaManagerManager
          .list("crew", this.$route.params.trainingCourseId, false)
          .then((crews) => {
            this.crews = crews;
          });
      } else if (
        this.questionnaire &&
        this.questionnaire.userReply &&
        this.questionnaire.userReply.triad &&
        this.questionnaire.userReply.triad.crew
      ) {
        this.crews = [this.questionnaire.userReply.triad.crew];
      }
    },

    toggleDescription(value, e) {
      e.stopPropagation();
      this.$emit("toggleDescription", value);
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  height: auto;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  color: $blue;

  @include dark-theme {
    color: $white;
  }
}

.titleBlock {
  padding: 0 110px;

  @include up-to-lg {
    margin-top: 30px;
  }

  @include up-to-md {
    margin-top: 0;
    padding: 0;
  }
}

form.titleBlock {
  max-height: 40px;

  button {
    max-height: 40px;
    max-width: 40px;
  }
}

.editable {
  cursor: pointer;
}

.description {
  margin-bottom: 15px;

  @include dark-theme {
    color: $white;
  }
}

.dates {
  text-align: center;

  @include dark-theme {
    color: $white;
  }
}

.widget {
  height: auto;
  margin-bottom: 0;

  &.sticky {
    position: sticky;
    top: 129px;
    z-index: 1000;

    @include up-to-md {
      top: calc(env(safe-area-inset-top, 0px) + 105px);

      &.isProgressBar {
        top: calc(env(safe-area-inset-top, 0px) + 120px);
      }
    }
  }

  @include up-to-md {
    position: initial;
  }

  @include up-to-lg {
    margin-bottom: 18px;
  }

  .widget-heading {
    flex-direction: column;
  }
}

.active {
  position: absolute;
  top: 20px;
  right: 10px;
  display: flex;
  align-items: center;

  @include up-to-lg {
    right: 50%;
    transform: translateX(50%);
  }
}

.descriptionPdf {
  width: 100%;
  max-width: 1173px;
  aspect-ratio: 0.8;
  margin-top: 15px;
}

.widget-content {
  max-height: initial;
  overflow: hidden;
  transition: all 300ms ease-in-out;

  &.hide {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
