<template>
  <div class="widget widget-summary-1">
    <div class="widget-heading">
      <h5>{{ data.label ? data.label : $t("dashboard.indicator") }}</h5>
    </div>
    <div class="widget-content">
      <div v-for="item in statistic" :key="item.label" class="elem">
        <StatisticBlockComponent :data="item" />
      </div>
    </div>
  </div>
</template>

<script>
import StatisticBlockComponent from "@/components/blocks/statistic/StatisticBlockComponent.vue";

export default {
  name: "IndicatorBlock",
  methods: {},
  components: { StatisticBlockComponent },
  props: {
    data: {
      type: [],
      required: true,
    },
  },
  data() {
    return {
      statistic: [],
    };
  },
  watch: {
    data() {
      this.statistic = this.data.indicators;
    },
  },
  mounted() {
    this.statistic = this.data.indicators;
  },
};
</script>

<style lang="scss" scoped>
.elem + .elem {
  margin-top: 10px;
}
</style>
