export default {
  header: {
    dark: "Thème sombre",
    light: "Thème clair",
    system: "Thème du navigateur",
    notification: {
      empty: "Aucun traitement en cours...",
      waiting: "En attente d'informations sur le traitement",
      list: "Voir tout l'historique",
      historic: "Historique des traitements",
    },
  },
  dashboard: {
    timeline: "Timeline",
    indicator: "Indicateurs",
    indicators: {
      see_details: "Afficher/cacher les taux de remplissage par profil",
      fill_rate: "Taux de remplissage",
    },
    shared_docs: "Documents partagés",
    registration: "Inscriptions",
    welcome_to_studea: "Bienvenue sur votre portail Studea !",
    your_actually_are: "Vous êtes actuellement",
    welcome_message: ({ named }) =>
      `Bienvenue <strong>${named("name")}</strong> sur votre portail Studea !`,
    no_training_course_archived: "Aucune promotion archivé",
    no_training_course_actived: "Aucune promotion active",
    no_triad: "Aucun trinôme",
    triad_search_placeholder: "Rechercher",
    contract: {
      type: "Type de contrat :",
      company: "Employeur :",
      dates: "Dates du contrat :",
      rupture_date: "Date de rupture",
      siret: "Siret :",
      address: "Adresse :",
      from: "Du",
      to: "au",
      secondary: "secondaire",
    },
    archived_training_course: ({ plural, named }) =>
      plural([
        `Voir les ${named("label")}s archivées`,
        `Voir les ${named("label")}s archivés`,
      ]),
    unarchived_training_course: ({ plural, named }) =>
      plural([
        `Voir les ${named("label")}s actives`,
        `Voir les ${named("label")}s actifs`,
      ]),
    Download_the_booklet: "Télécharger le livret",
    Download_key_dates: "Télécharger les dates clés",
    session_lock: "Cette session n'est pas encore ouverte à la saisie.",
    skill_lock:
      "Vous ne pouvez pas modifier \ncette compétence qui est \ncalculée par vos réponses aux \nquestionnaires ou qui ne vous \nest pas affectée",
  },
};
