import router from "@/router";
import { apiConnection } from "@/services/api-connection";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { storageManager } from "@/services/storage/storage-manager";

const URL_SEND_NEW_PASSWORD = "/app/user/reset-password";
const URL_RESET_PASSWORD = "/app/user/ask-reset-password";

class LoginManager {
  token = "";
  refreshToken = "";
  stayConnected = 0;
  apiPath = "";

  constructor() {
    this.reloadToken();
  }

  setApiPath(platform) {
    const url = "https://" + platform.hostname + "/api";
    const date = new Date();
    window.localStorage.setItem("studea-apiPathDate", date.toISOString());
    window.localStorage.setItem("studea-apiPath", url);
    window.sessionStorage.setItem("studea-apiPath", url);
    localStorage.setItem(
      "studea-activateChatbot",
      platform.activateChatBot ? 1 : 0,
    );
    this.apiPath = url;
  }

  getApiPath() {
    return window.localStorage.getItem("studea-apiPath")
      ? window.localStorage.getItem("studea-apiPath")
      : window.sessionStorage.getItem("studea-apiPath");
  }

  reloadToken() {
    this.token = this.getToken();
    this.refreshToken = this.getRefreshToken();
    this.stayConnected = this.getStayConnected();
    const localApiPath = this.getApiPath();
    if (typeof localApiPath == "string") {
      this.apiPath = localApiPath;
    }
  }

  setToken(token, refreshToken, apiPath, stayConnected = false) {
    this.token = token;
    this.refreshToken = refreshToken;

    const swicthed =
      sessionStorage.getItem("studea-switched") &&
      sessionStorage.getItem("studea-switched") === "1";

    if (
      localStorage.getItem("studea-newTab") &&
      localStorage.getItem("studea-newTab") === "1"
    ) {
      storageManager.setFromLocalStorage();
    } else {
      if (stayConnected && !swicthed) {
        window.localStorage.setItem("studea-refreshToken", refreshToken);
        window.localStorage.setItem("studea-token", token);
        window.localStorage.setItem("studea-apiPath", apiPath);
        window.localStorage.setItem("studea-stayConnected", "1");
      }
      window.sessionStorage.setItem("studea-refreshToken", refreshToken);
      window.sessionStorage.setItem("studea-token", token);
      window.sessionStorage.setItem("studea-apiPath", apiPath);
      window.sessionStorage.setItem("studea-sessionTS", Date.now());
    }
  }

  isToken() {
    return this.getToken() || this.getRefreshToken();
  }

  getToken() {
    if (
      window.sessionStorage.getItem("studea-switched") &&
      window.sessionStorage.getItem("studea-switched") === "1"
    ) {
      return window.sessionStorage.getItem("studea-token");
    } else {
      return window.localStorage.getItem("studea-token")
        ? window.localStorage.getItem("studea-token")
        : window.sessionStorage.getItem("studea-token");
    }
  }

  getRefreshToken() {
    if (
      window.sessionStorage.getItem("studea-switched") &&
      window.sessionStorage.getItem("studea-switched") === "1"
    ) {
      return window.sessionStorage.getItem("studea-refreshToken");
    } else {
      return window.localStorage.getItem("studea-refreshToken")
        ? window.localStorage.getItem("studea-refreshToken")
        : window.sessionStorage.getItem("studea-refreshToken");
    }
  }

  getStayConnected() {
    return parseInt(window.localStorage.getItem("studea-stayConnected"));
  }

  refreshConnection() {
    this.reloadToken();
    const credentials = {
      refresh_token: this.refreshToken,
    };
    return new Promise((resolve, reject) => {
      if (this.refreshToken) {
        apiConnection
          .fetchApi("post", this.apiPath + "/token/refresh", credentials, false)
          .then((data) => {
            if (data.token) {
              if (data.token) {
                this.setToken(
                  data.token,
                  data.refresh_token,
                  this.apiPath,
                  parseInt(window.localStorage.getItem("studea-stayConnected")),
                );
                resolve({ succes: true });
              } else {
                reject(data);
              }
            }
          })
          .catch(function (error) {
            if (error.code === 401) {
              storageManager.clear(true);
              window.location.reload();
            }
          });
      } else {
        const url = new URL(window.location.href);
        let query = "";
        if (url.pathname !== "/login") {
          query = { referer: url.pathname + url.search };
        }
        router.push({ name: "Login", query: query });
      }
    });
  }

  login(login, password, stayConnected = false) {
    const credentials = {
      username: login,
      password: password,
    };
    return new Promise((resolve) => {
      apiConnection
        .fetchApi("post", this.apiPath + "/login_check", credentials, false)
        .then((data) => {
          if (data.token) {
            this.setToken(
              data.token,
              data.refresh_token,
              this.apiPath,
              stayConnected,
            );
            resolve({ success: true, token: data.token });
          }
        })
        .catch((res) => {
          resolve({ success: false, message: res.message, code: res.code });
        });
    });
  }

  resetPassword(username) {
    return apiConnection
      .post(URL_RESET_PASSWORD, { username: username }, false)
      .then(() => {
        notificationManager.showNotification(
          "success",
          i18n.global.t("reset_password_confirm"),
          10000,
          "top-end",
          false,
          "-no-header",
        );
      });
  }

  newPassword(password, hash) {
    return apiConnection
      .post(URL_SEND_NEW_PASSWORD + "/" + hash, { password: password }, false)
      .then((data) => {
        if (!data.hasError) {
          notificationManager.showNotification(
            "success",
            i18n.global.t("new_password_confirm"),
            10000,
            "top-end",
            false,
            "-no-header",
          );
        } else {
          notificationManager.showNotification(
            "error",
            i18n.global.t("reset_link_expired"),
            10000,
            "top-end",
            false,
            "-no-header",
          );
        }
      });
  }

  storeInitialToken() {
    const initialToken = window.localStorage.getItem("studea-token")
      ? window.localStorage.getItem("studea-token")
      : window.sessionStorage.getItem("studea-token");
    const initialRefreshToken = window.localStorage.getItem(
      "studea-refreshToken",
    )
      ? window.localStorage.getItem("studea-refreshToken")
      : window.sessionStorage.getItem("studea-refreshToken");

    window.sessionStorage.setItem("studea-initial-token", initialToken);
    window.sessionStorage.setItem(
      "studea-initial-refreshToken",
      initialRefreshToken,
    );

    window.sessionStorage.removeItem("studea-token");
    window.sessionStorage.removeItem("studea-refreshToken");
  }
}

export const loginManager = new LoginManager();
