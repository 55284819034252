import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "./router";
import { createHead } from "@vueuse/head";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
// bootstrap
import * as bootstrap from "bootstrap";
// modals
import "@/assets/sass/components/custom-modal.scss";

//Sweetalert
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue3-apexcharts";

// perfect scrollbar
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

//VueTelInput
import "vue-tel-input/dist/vue-tel-input.css";
import { i18n } from "@/i18n";

//Font Awesome and icon library
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";

//tooltip
import { tooltip } from "@/directives/tooltip";
import { access } from "@/directives/access";

//store
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useMainStore } from "@/store/main/main";
import { useTriadStore } from "@/store/triad/triad";
import { usePlatformStore } from "@/store/platform/platform";
import { useThemeStore } from "@/store/theme/theme";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";
import { useStudeaManagerQuestionnaireStore } from "@/store/studea_manager/studea-manager-questionnaire";
import { useUserStore } from "@/store/user/user";
import { useStudeaManagerStore } from "@/store/studea_manager/studea-manager";
import { useAdminStore } from "@/store/admin/admin";
import { useErrorStore } from "@/store/error/error";
import { useNotificationStore } from "@/store/notifications/notification";
// set default settings
import appSetting from "./app-setting";

const pjson = require("../package.json");

window.bootstrap = bootstrap;

//vue-meta
const head = createHead();

library.add(fas, fal);
dom.watch();

const pinia = createPinia();

const app = createApp(App).use(pinia);
app
  .use(router)
  .use(head)
  .use(i18n)
  .use(VueSweetalert2)
  .use(VueApexCharts)
  .use(PerfectScrollbar)
  .component("FontAwesomeIcon", FontAwesomeIcon);

app.directive("tooltip", tooltip).directive("access", access);

export const mainStore = useMainStore();
export const trainingCourseStore = useTrainingCourseStore();
export const questionnaireStore = useQuestionnaireStore();
export const triadStore = useTriadStore();
export const platformStore = usePlatformStore();
export const themeStore = useThemeStore();
export const studeaManagerQuestionnaireStore =
  useStudeaManagerQuestionnaireStore();
export const userStore = useUserStore();
export const studeaManagerStore = useStudeaManagerStore();
export const adminStore = useAdminStore();
export const errorStore = useErrorStore();
export const notificationStore = useNotificationStore();

if (
  process.env.VUE_APP_SENTRY_ENV === "prod" ||
  process.env.VUE_APP_SENTRY_ENV === "uat"
) {
  Sentry.init({
    app,
    environment: process.env.VUE_APP_SENTRY_ENV,
    dsn: "https://e5e3525696bb4e550c90671372c6d809@sentry.effetb.com/4",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

window.$appSetting = appSetting;
window.$appSetting.init();

router.isReady().then(() => {
  app.mount("#app");
  useMainStore().setAppBuildVersion(pjson.version);
});
